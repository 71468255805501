import React from 'react';

import styles from './index.module.scss';

function withWrapper(WrappedComponent) {
  return props => (
    <div className={styles.iconWrapper}>
      <WrappedComponent {...props} />
    </div>
  );
}

export default withWrapper;
