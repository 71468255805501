import React from 'react';
import withWrapper from 'hocs/withWrapper';

const MoveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7129 1.30691C11.6245 1.34351 11.5416 1.39776 11.4697 1.46967L8.46967 4.46967C8.17678 4.76256 8.17678 5.23744 8.46967 5.53033C8.76256 5.82322 9.23744 5.82322 9.53033 5.53033L11.25 3.81066V11.25H3.81066L5.53033 9.53033C5.82322 9.23744 5.82322 8.76256 5.53033 8.46967C5.23744 8.17678 4.76256 8.17678 4.46967 8.46967L1.46967 11.4697C1.39776 11.5416 1.34351 11.6245 1.30691 11.7129C1.27024 11.8013 1.25 11.8983 1.25 12C1.25 12.1919 1.32322 12.3839 1.46967 12.5303L4.46967 15.5303C4.76256 15.8232 5.23744 15.8232 5.53033 15.5303C5.82322 15.2374 5.82322 14.7626 5.53033 14.4697L3.81066 12.75H11.25V20.1893L9.53033 18.4697C9.23744 18.1768 8.76256 18.1768 8.46967 18.4697C8.17678 18.7626 8.17678 19.2374 8.46967 19.5303L11.4697 22.5303C11.5416 22.6022 11.6245 22.6565 11.7129 22.6931C11.8013 22.7298 11.8983 22.75 12 22.75C12.1919 22.75 12.3839 22.6768 12.5303 22.5303L15.5303 19.5303C15.8232 19.2374 15.8232 18.7626 15.5303 18.4697C15.2374 18.1768 14.7626 18.1768 14.4697 18.4697L12.75 20.1893V12.75H20.1893L18.4697 14.4697C18.1768 14.7626 18.1768 15.2374 18.4697 15.5303C18.7626 15.8232 19.2374 15.8232 19.5303 15.5303L22.5303 12.5303C22.6022 12.4584 22.6565 12.3755 22.6931 12.2871C22.7298 12.1987 22.75 12.1017 22.75 12C22.75 11.8081 22.6768 11.6161 22.5303 11.4697L19.5303 8.46967C19.2374 8.17678 18.7626 8.17678 18.4697 8.46967C18.1768 8.76256 18.1768 9.23744 18.4697 9.53033L20.1893 11.25H12.75V3.81066L14.4697 5.53033C14.7626 5.82322 15.2374 5.82322 15.5303 5.53033C15.8232 5.23744 15.8232 4.76256 15.5303 4.46967L12.5303 1.46967C12.3839 1.32322 12.1919 1.25 12 1.25C11.8983 1.25 11.8013 1.27024 11.7129 1.30691Z"
      fill="white"
    />
  </svg>
);

export default withWrapper(MoveIcon);
