const calculateMaxScale = (
  newWidth,
  newHeight,
  originalWidth,
  originalHeight,
) => {
  const scaleX = newWidth / originalWidth;
  const scaleY = newHeight / originalHeight;
  const maxScale = scaleX > scaleY ? scaleX : scaleY;
  return maxScale;
};

export default calculateMaxScale;
