import Compressor from 'compressorjs';
import base64ToFile from 'utils/base64ToFile';
import { TURN_INTO_VIDEO_BASE_LINK } from 'constants/index';

const AVAILABLE_SPACE = 3000;

const additionalInfo = '?keyword=fun&utm_content=image-resizer';

const cleanLocalStorage = () => {
  localStorage.clear();
};

const calcBase64SizeInKB = string => 3 + (string.length * 16) / (8 * 1024);

const changeLocation = link => {
  window.location.href = link;
};

const getCompressedImage = async (file, quality) => {
  /* eslint-disable */
  let image = '';

  let promise = new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);

        reader.onload = function() {
          const {result: resizedBase64String} = reader;

          image = resizedBase64String;
          resolve(image);
        };
      },
    });
  })

  image = await promise;

  return image;
}

const setImageToLocalStorage = async (base64String, key) => {
  const sizeKB = calcBase64SizeInKB(base64String);
  const file = base64ToFile(base64String, 'image.jpeg');

  let imageToSetToLocalStorage = base64String;
  if (sizeKB > AVAILABLE_SPACE) {
    const quality = AVAILABLE_SPACE / sizeKB;

    if (!file) {
      return;
    }

    imageToSetToLocalStorage = await getCompressedImage(file, quality);
  }

  cleanLocalStorage();

  localStorage.setItem(key, imageToSetToLocalStorage);
  changeLocation(`${TURN_INTO_VIDEO_BASE_LINK}${key}${additionalInfo}`);
};

export default setImageToLocalStorage;
