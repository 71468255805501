const getCroppedCenterPoints = (
  originalWidth,
  originalHeight,
  newWidth,
  newHeight,
) => {
  const centerX = Math.round(originalWidth / 2);
  const centerY = Math.round(originalHeight / 2);
  const halfOfNewWidth = Math.round(newWidth / 2);
  const halfOfNewHeight = Math.round(newHeight / 2);
  const startPointX = Math.round(centerX - halfOfNewWidth);
  const startPointY = Math.round(centerY - halfOfNewHeight);
  const endPointX = Math.floor(centerX + halfOfNewWidth);
  const endPointY = Math.floor(centerY + halfOfNewHeight);
  return {
    x1: Math.abs(startPointX),
    x2: Math.abs(endPointX),
    y1: Math.abs(startPointY),
    y2: Math.abs(endPointY),
  };
};

export default getCroppedCenterPoints;
