import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import saveAs from 'file-saver';

import trackEvent from 'utils/promoReportingWrapper';
import base64ToFile from 'utils/base64ToFile';
import { connect, actions } from 'store';
import downloadAll from 'utils/downloadAll';
import { EXTENSION_TO_DOWNLOAD, EXTENSION_TO_CONVERT } from 'constants/index';

import Typography from 'components/Typography';

import {
  clearObjectStore,
  putObjectInDatabase,
} from 'services/image-state-service';
import { userService } from 'services/user-session-service';
import styles from './index.module.scss';

class StickyBar extends Component {
  state = {
    selectedCounter: 0,
  };

  componentDidMount() {
    window.addEventListener(
      'stickyBarDownloadInit',
      e => {
        const { type } = e.detail;
        if (type === 'selected') {
          this.handleDownloadSelected();
        } else {
          this.handleDownloadAll();
        }
      },
      false,
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedCounter: this.getSelectedCounter(nextProps.thumbnails),
    });
  }

  getSelectedCounter = thumbnails =>
    Object.keys(thumbnails).reduce((sum, socialName) => {
      const selectedBySocial = thumbnails[socialName].reduce(
        (total, obj) => (obj.selected ? total + 1 : total),
        0,
      );
      return selectedBySocial + sum;
    }, 0);

  downloadSingleImage = objWithSelected => {
    const { pagePlatform } = this.props;
    Object.keys(objWithSelected).forEach(socialName => {
      objWithSelected[socialName].forEach(socialObj => {
        saveAs(
          base64ToFile(
            socialObj.source,
            `Promo-${socialName}-${
              socialObj.formatName
            }.${EXTENSION_TO_DOWNLOAD}`,
          ),
        );
        trackEvent('IMR image downloaded successfully', {
          isMulti: false,
          countOfImages: 1,
          buttonSection: 'sticky download bar - selected',
          categoryTypeArray: [socialName],
          imageNameArray: [`${socialName} ${socialObj.formatName}`],
          pagePlatform,
        });
      });
    });
  };

  downloadSelectedImages = objWithSelected => {
    const { pagePlatform } = this.props;
    const imageNameArray = [];

    Object.keys(objWithSelected).forEach(social => {
      objWithSelected[social].forEach(imageName => {
        imageNameArray.push(`${social} ${imageName.formatName}`);
      });
    });

    trackEvent('IMR image downloaded successfully', {
      isMulti: true,
      countOfImages: Object.keys(objWithSelected).reduce(
        (total, socialName) => objWithSelected[socialName].length + total,
        0,
      ),
      buttonSection: 'sticky download bar - selected',
      categoryTypeArray: Object.keys(objWithSelected),
      imageNameArray,
      pagePlatform,
    });

    downloadAll(objWithSelected);
  };

  getObjWithSelectedImages = () => {
    const { thumbnails } = this.props;
    const objWithSelected = {};

    Object.keys(thumbnails).forEach(socialName => {
      thumbnails[socialName].forEach(socialObj => {
        if (socialObj.selected && objWithSelected[socialName]) {
          objWithSelected[socialName].push(socialObj);
        }
        if (socialObj.selected && !objWithSelected[socialName]) {
          objWithSelected[socialName] = [];
          objWithSelected[socialName].push(socialObj);
        }
      });
    });

    return objWithSelected;
  };

  handleDownloadSelected = async () => {
    const userInfo = await userService.getUser();
    if (userInfo) {
      await clearObjectStore({
        storeName: 'downloadQueue',
      });
      const { selectedCounter } = this.state;
      const { pagePlatform } = this.props;

      trackEvent('IMR image downloaded button clicked', {
        buttonSection: 'sticky download bar - selected',
        pagePlatform,
      });

      const objWithSelected = this.getObjWithSelectedImages();

      if (selectedCounter === 1) {
        this.downloadSingleImage(objWithSelected);
      } else {
        this.downloadSelectedImages(objWithSelected);
      }
    } else {
      await putObjectInDatabase({
        name: 'downloadQueue',
        storeName: 'downloadQueue',
        data: {
          type: 'selected',
        },
      });
      actions.openPopup({ type: 'signup', isOpen: true });
    }
  };

  handleDownloadAll = async () => {
    const userInfo = await userService.getUser();
    if (userInfo) {
      const { pagePlatform, thumbnails, originalImage } = this.props;
      const { width: newWidth, height: newHeight } = originalImage;
      await clearObjectStore({
        storeName: 'downloadQueue',
      });

      const { editor } = window;
      let image;
      if (editor && pagePlatform === 'desktop') {
        image = editor
          .getCroppedCanvas({
            width: parseFloat(newWidth),
            height: parseFloat(newHeight),
          })
          .toDataURL(EXTENSION_TO_CONVERT);
      }

      downloadAll(thumbnails, image);

      trackEvent('IMR image downloaded successfully', {
        countOfImages: Object.keys(thumbnails).reduce(
          (total, socialName) => thumbnails[socialName].length + total,
          0,
        ),
        isMulti: true,
        buttonSection: 'sticky download bar - all',
        categoryTypeArray: Object.keys(thumbnails),
        imageNameArray: 'all',
        pagePlatform,
      });
    } else {
      await putObjectInDatabase({
        name: 'downloadQueue',
        storeName: 'downloadQueue',
        data: {
          type: 'all',
        },
      });
      actions.openPopup({ type: 'signup', isOpen: true });
    }
  };

  render() {
    const {
      originalImage,
      pagePlatform,
      progress: { total, loaded },
      isLoadingFromDb,
    } = this.props;

    const { selectedCounter } = this.state;
    if (!originalImage.source || total !== loaded) return null;

    return (
      <Fragment>
        <div className={styles.stickyWrapper}>
          <div className={styles.selected}>
            <Typography variant="subtitle" className={styles.counter}>
              {selectedCounter}
            </Typography>
            <Typography variant="subtitle" className={styles.label}>
              {selectedCounter > 1 ? 'images' : 'image'} selected
            </Typography>
          </div>
          <div className={styles.buttonsWrapper}>
            <button
              disabled={isLoadingFromDb}
              type="button"
              onClick={this.handleDownloadAll}
            >
              {pagePlatform === 'mobile'
                ? 'Download All Images'
                : 'Download All Images'}
            </button>
            <button
              disabled={!selectedCounter || isLoadingFromDb}
              type="button"
              onClick={this.handleDownloadSelected}
            >
              {pagePlatform === 'mobile'
                ? 'Download Selected Images'
                : 'Download Selected Images'}
            </button>
          </div>
          <div className={classnames(styles.selected, styles.fakeBox)}>
            <Typography variant="subtitle" className={styles.counter}>
              {selectedCounter}
            </Typography>
            <Typography variant="subtitle" className={styles.label}>
              IMAGE SELECTED
            </Typography>
          </div>
        </div>
      </Fragment>
    );
  }
}

StickyBar.propTypes = {
  originalImageDimensions: propTypes.shape({
    x: propTypes.number,
    y: propTypes.number,
  }).isRequired,
  progress: propTypes.shape({
    total: propTypes.number,
    loaded: propTypes.number,
  }).isRequired,
  originalImage: propTypes.shape({
    width: propTypes.string,
    height: propTypes.string,
    source: propTypes.string,
    xScale: propTypes.string,
    yScale: propTypes.string,
  }).isRequired,
  thumbnails: propTypes.object.isRequired,
  pagePlatform: propTypes.string.isRequired,
  isLoadingFromDb: propTypes.bool.isRequired,
};

const mapStateToProps = ({
  originalImage,
  thumbnails,
  pagePlatform,
  progress,
  originalImageDimensions,
  isLoadingFromDb,
}) => ({
  progress,
  originalImage,
  thumbnails,
  pagePlatform,
  originalImageDimensions,
  isLoadingFromDb,
});

export default connect(mapStateToProps)(StickyBar);
