import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const DefaultPopup = ({ description, buttons }) => (
  <Fragment>
    <div className={styles.description}>
      {description.map(text => (
        <p key={text}>{text}</p>
      ))}
    </div>
    <div className={styles.buttonsWrapper}>
      {Object.keys(buttons).map((key, i) => (
        <button
          key={String(i)}
          onClick={event => {
            event.persist();
            buttons[key].handler();
          }}
          type="button"
          className={styles[key]}
        >
          {buttons[key].text}
        </button>
      ))}
    </div>
  </Fragment>
);

DefaultPopup.propTypes = {
  description: PropTypes.arrayOf(PropTypes.any).isRequired,
  buttons: PropTypes.shape({
    whiteButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
    blueButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

export default DefaultPopup;
