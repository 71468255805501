import React from 'react';

const Email = () => (
  <svg
    width="41px"
    height="40px"
    viewBox="0 0 41 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Image-Uploaded-Full-size"
        transform="translate(-905.000000, -10512.000000)"
      >
        <g id="socials" transform="translate(734.000000, 10512.000000)">
          <g id="email" transform="translate(171.000000, 0.000000)">
            <path
              d="M20.0374435,0.975121951 C9.54291787,0.975121951 1.0052882,9.50926829 1.0052882,19.9995122 C1.0052882,30.4892683 9.54291787,39.0234146 20.0374435,39.0234146 C30.5314811,39.0234146 39.0686228,30.4892683 39.0686228,19.9995122 C39.0686228,9.50926829 30.5314811,0.975121951 20.0374435,0.975121951 L20.0374435,0.975121951 Z M20.0374435,39.9990244 C9.00513748,39.9990244 0.0292802389,31.0273171 0.0292802389,19.9995122 C0.0292802389,8.97121951 9.00513748,-0.000487804878 20.0374435,-0.000487804878 C31.0697495,-0.000487804878 40.0446308,8.97121951 40.0446308,19.9995122 C40.0446308,31.0273171 31.0697495,39.9990244 20.0374435,39.9990244 L20.0374435,39.9990244 Z"
              id="Fill-4"
              fill="#FFFFFF"
            />
            <circle
              id="Oval"
              fill="#FFFFFF"
              fillRule="nonzero"
              cx="20"
              cy="20"
              r="20"
            />
            <g
              id="mail"
              transform="translate(12.000000, 14.000000)"
              stroke="#6D92F5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.8"
            >
              <path
                d="M1.6,0 L14.4,0 C15.28,0 16,0.73125 16,1.625 L16,11.375 C16,12.26875 15.28,13 14.4,13 L1.6,13 C0.72,13 0,12.26875 0,11.375 L0,1.625 C0,0.73125 0.72,0 1.6,0 Z"
                id="Path"
              />
              <polyline id="Path" points="16 1.625 8 7.3125 0 1.625" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Email;
