import React, { useCallback } from 'react';
import PromoHeader from '@easyhi/promo-composite-components/lib/components/promo-header';
import PromoHeaderConfig from '@easyhi/promo-composite-components/lib/config/header-config';
import { useUserLimits } from '@easyhi/promo-composite-components/lib/components/user-limits';
import useShowEmbedPricing from '../../hooks/use-show-embed-pricing';

const Sticky = ({ accountInfo, featureFlags }) => {
  const [userLimits] = useUserLimits();
  const showEmbedPricing = useShowEmbedPricing();

  const onShowEmbedPricing = useCallback(
    () => {
      showEmbedPricing({ successUrl: window.location.href });
    },
    [showEmbedPricing],
  );

  return (
    <PromoHeader
      isTransparent
      accountInfo={accountInfo}
      items={PromoHeaderConfig}
      featureFlags={featureFlags}
      userLimits={userLimits}
      onGetUnlimitedProjects={onShowEmbedPricing}
      onGetMorePremiumClips={onShowEmbedPricing}
      onGetMoreAIVideos={onShowEmbedPricing}
    />
  );
};

export default Sticky;
