import { useCallback } from 'react';
import useEmbedPricing from './use-embed-pricing';
import useEmbedPricingParams from './use-embed-pricing-params';

const delay = time => new Promise(resolve => setTimeout(() => resolve(), time));

const useShowEmbedPricing = () => {
  const [, setEmbedPricing] = useEmbedPricing();
  const [, setEmbedPricingParams] = useEmbedPricingParams();

  return useCallback(
    async params => {
      setEmbedPricingParams(params);
      await delay(100); // todo: temporary solution to let pricing page update its properties
      setEmbedPricing(true);
    },
    [setEmbedPricing, setEmbedPricingParams],
  );
};

export default useShowEmbedPricing;
