import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@easyhi/promo-design-system/lib/button';
import Icon from '@easyhi/promo-design-system/lib/icon';

import styles from './index.module.scss';

const SignupPopup = ({ title, description, onClose }) => (
  <div className={styles.signupPopup}>
    <div className={styles.content}>
      <div className={styles.close} onClick={onClose}>
        <Icon name="X" size="medium" />
      </div>
      <img
        className={styles.image}
        src="https://ak03-cdn.slidely.com/static/photo-resizer/opening.png"
        alt="Promo.com"
      />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.subtitle}>{description}</p>
      <Button
        size="medium"
        variant="primary"
        href="/signup?redirect_url=/tools/image-resizer/"
      >
        Sign up
      </Button>
      <p className={classnames(styles.login, 'button-medium')}>
        Have an account?{' '}
        <a href="/login?redirect_url=/tools/image-resizer/">Login</a>
      </p>
    </div>
  </div>
);

SignupPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

SignupPopup.defaultProps = {
  title: 'Sign up to download your content',
  description: `Don't worry.  It's free and gives you access to even
more amazing creative tools by Promo`,
};

export default SignupPopup;
