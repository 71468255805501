import JSZip from 'jszip';
import saveAs from 'file-saver';

import { EXTENSION_TO_DOWNLOAD, ZIP_ARCHIVE_NAME } from 'constants/index';

import base64ToFile from './base64ToFile';

const downloadImages = (thumbnails, editedImage = false) => {
  const zip = new JSZip();
  const fileExtension = EXTENSION_TO_DOWNLOAD;

  if (editedImage) {
    zip.file(
      `Promo-edited-image.${fileExtension}`,
      base64ToFile(editedImage, `Promo-edited-image.${fileExtension}`),
      {
        base64: true,
      },
    );
  }

  Object.keys(thumbnails).forEach(socialName => {
    const folder = zip.folder(socialName);

    thumbnails[socialName].forEach(socialObj => {
      folder.file(
        `Promo-${socialName}-${socialObj.formatName}.${fileExtension}`,
        base64ToFile(
          socialObj.source,
          `Promo-${socialName}-${socialObj.formatName}.${fileExtension}`,
        ),
        {
          base64: true,
        },
      );
    });
  });
  zip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, `${ZIP_ARCHIVE_NAME}.zip`);
  });
};

export default downloadImages;
