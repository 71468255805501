import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import Slider from 'react-rangeslider';
import Cropper from 'react-cropper-axon';
import throttle from 'lodash.throttle';

import { connect, actions } from 'store';
import trackEvent from 'utils/promoReportingWrapper';
import Replace from 'assets/icons/Replace';
import { TrashIcon, FlipIcon, MoveIcon } from 'components/EditorActionButtons';
import styles from './index.module.scss';

class ImageEditor extends Component {
  state = {
    displayPanIcon: true,
    panIconSizes: {
      width: 0,
      height: 0,
    },
  };

  handleMouseMouve = throttle(event => {
    if (this.isCursorInCenter(event)) {
      this.setState({ displayPanIcon: false });
    } else {
      this.setState({ displayPanIcon: true });
    }
  }, 100);

  setPanIconRef = ref => {
    this.panIcon = ref;

    if (!this.panIcon) {
      return;
    }

    const panIconBounds = this.panIcon.getBoundingClientRect();
    this.setState({
      panIconSizes: {
        width: panIconBounds.width,
        height: panIconBounds.height,
      },
    });
  };

  setEditorRef = editorRef => {
    const { setEditorRef } = this.props;
    if (editorRef) {
      this.editorRef = editorRef.cropper;
      window.editor = editorRef.cropper;
      setEditorRef(editorRef);
    }
  };

  isCursorInCenter = event => {
    const { panIconSizes } = this.state;

    if (!this.editorRef || !this.editorRef.containerData) return;

    const bounds = this.cropperContainer.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;

    const containerWidth = this.editorRef.containerData.width;
    const containerHeight = this.editorRef.containerData.height;

    const panIconWidth = panIconSizes.width;
    const panIconHeight = panIconSizes.height;

    const halfOfContainerWidth = containerWidth / 2;
    const halfOfIconWidth = panIconWidth / 2;

    const halfOfContainerHeight = containerHeight / 2;
    const halfOfIconHeight = panIconHeight / 2;

    if (
      x > halfOfContainerWidth - halfOfIconWidth &&
      x < halfOfContainerWidth + halfOfIconWidth &&
      (y > halfOfContainerHeight - halfOfIconHeight &&
        y < halfOfContainerHeight + halfOfIconHeight)
    ) {
      return true;
    }

    return false;
  };

  handleTrashIconClicked = () => {
    const { pagePlatform, handleImageRemove } = this.props;

    trackEvent('IMR trash icon clicked', {
      pagePlatform,
    });

    actions.openPopup({
      type: 'default',
      isOpen: true,
      title: 'Remove Your Image',
      description: [
        'Removing the image will remove all the resized images and any edits you’ve made to them.',
        'Are you sure you would like to remove your image?',
      ],
      buttons: {
        whiteButton: {
          text: 'Close',
          handler: actions.closePopup,
        },
        redButton: {
          text: 'Remove',
          handler: handleImageRemove,
        },
      },
    });
  };

  render() {
    const { displayPanIcon } = this.state;
    const {
      pagePlatform,
      originalImage: { source },
      zoomParams,
      sliderScale,
      editorContainerStyles,
      zoomScale,
      updateScale,
      flipImage,
      afterImageReady,
      onCrop,
      debouncedTrack,
    } = this.props;
    return (
      <Fragment>
        <div
          className={styles.imageEditor}
          onMouseMove={event => {
            event.persist();
            this.handleMouseMouve(event);
          }}
          ref={ref => (this.cropperContainer = ref)}
        >
          <Cropper
            background={false}
            center
            className={styles.mainImageEditor}
            crop={e => {
              onCrop(e);
              this.setState({ displayPanIcon: false });
            }}
            cropstart={() => {
              this.setState({ displayPanIcon: false });
              trackEvent('IMR pan image', {
                pagePlatform,
                buttonSection: 'image resizer editor',
              });
            }}
            cropend={() => this.setState({ displayPanIcon: true })}
            cropBoxMovable
            cropBoxResizable={false}
            dragMode="move"
            guides={false}
            modal={false}
            movable
            ready={afterImageReady}
            ref={this.setEditorRef}
            src={source}
            style={editorContainerStyles}
            toggleDragModeOnDblclick={false}
            viewMode={0}
            zoomTo={zoomScale}
            zoom={ev => {
              if (ev.detail.originalEvent instanceof WheelEvent) {
                debouncedTrack(pagePlatform);
                updateScale(ev, 'wheel');
              }
            }}
          />
          <button
            type="button"
            className={styles.moveImage}
            ref={this.setPanIconRef}
            style={!displayPanIcon ? { display: 'none' } : { display: 'block' }}
          >
            <MoveIcon />
          </button>
        </div>
        <button
          type="button"
          className={styles.flipImage}
          data-rh="Horizontal Flip"
          onClick={flipImage}
        >
          <FlipIcon />
        </button>
        <button
          type="button"
          className={styles.deleteImage}
          data-rh="Remove your image from all image formats below"
          onClick={this.handleTrashIconClicked}
        >
          <TrashIcon />
        </button>
        <button
          type="button"
          className={styles.deleteImageMobile}
          onClick={this.handleTrashIconClicked}
        >
          <Replace />
          Replace Image
        </button>
        <div className={styles.slider}>
          <Slider
            onChange={updateScale}
            value={sliderScale}
            max={zoomParams.max}
            min={zoomParams.min}
            step={zoomParams.step}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ pagePlatform, originalImage }) => ({
  pagePlatform,
  originalImage,
});

ImageEditor.propTypes = {
  pagePlatform: propTypes.string.isRequired,
  originalImage: propTypes.shape({
    width: propTypes.string,
    height: propTypes.string,
    source: propTypes.string,
    xScale: propTypes.string,
    yScale: propTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(ImageEditor);
