const trackInit = async (action, payload) => {
  (await window.promoReporting).trackPageView(action, payload);
};

const trackEvent = async (action, payload) => {
  (await window.promoReporting).trackEvent(action, payload);
};

const changeGlobalParams = async params => {
  (await window.promoReporting).mergeGlobalParams(params);
};

export { trackInit, changeGlobalParams };

export default trackEvent;
