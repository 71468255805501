import React from 'react';

const DropZoneImage = () => (
  <svg
    width="46"
    height="47"
    viewBox="0 0 46 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 4.25C5.05584 4.25 3.75 5.55584 3.75 7.16667V39.8333C3.75 41.2201 4.71783 42.3808 6.01489 42.6769L31.0959 17.5959C31.7793 16.9125 32.8874 16.9125 33.5708 17.5959L42.25 26.2751V7.16667C42.25 5.55584 40.9442 4.25 39.3333 4.25H6.66667ZM45.75 30.4968V7.16667C45.75 3.62284 42.8772 0.75 39.3333 0.75H6.66667C3.12284 0.75 0.25 3.62284 0.25 7.16667V39.8333C0.25 43.3761 3.1212 46.2484 6.66364 46.25M45.75 30.4968C45.75 30.4989 45.75 30.5011 45.75 30.5032V39.8333C45.75 43.3772 42.8772 46.25 39.3333 46.25H6.66926M42.25 31.2249L32.3333 21.3082L10.8915 42.75H39.3333C40.9442 42.75 42.25 41.4442 42.25 39.8333V31.2249ZM14.8333 13.5833C13.8668 13.5833 13.0833 14.3668 13.0833 15.3333C13.0833 16.2998 13.8668 17.0833 14.8333 17.0833C15.7998 17.0833 16.5833 16.2998 16.5833 15.3333C16.5833 14.3668 15.7998 13.5833 14.8333 13.5833ZM9.58333 15.3333C9.58333 12.4338 11.9338 10.0833 14.8333 10.0833C17.7328 10.0833 20.0833 12.4338 20.0833 15.3333C20.0833 18.2328 17.7328 20.5833 14.8333 20.5833C11.9338 20.5833 9.58333 18.2328 9.58333 15.3333Z"
      fill="#BFC4CB"
    />
  </svg>
);

export default DropZoneImage;
