const getVariant = (width, height) => {
  if (width > height) {
    return 'horizontal';
  }
  if (width < height) {
    return 'vertical';
  }
  return 'square';
};

export default getVariant;
