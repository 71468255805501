const imageToJPG = (imgObj, originalWitdh, originalHeight) => {
  const canvas = document.createElement('canvas');
  const canvasCtx = canvas.getContext('2d');

  canvas.width = originalWitdh;
  canvas.height = originalHeight;

  canvasCtx.drawImage(imgObj, 0, 0, originalWitdh, originalHeight);

  return canvas.toDataURL('image/jpeg');
};

export default imageToJPG;
