import React from 'react';

const Close = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4107 0.46967C11.1178 0.176777 10.6429 0.176777 10.35 0.46967L5.88037 4.93934L1.4107 0.46967L1.32658 0.397052C1.03297 0.179197 0.616308 0.203403 0.350041 0.46967C0.0571478 0.762563 0.0571478 1.23744 0.350041 1.53033L4.81971 6L0.350041 10.4697L0.277423 10.5538C0.0595684 10.8474 0.0837745 11.2641 0.350041 11.5303C0.642934 11.8232 1.11781 11.8232 1.4107 11.5303L5.88037 7.06066L10.35 11.5303L10.4342 11.6029C10.7278 11.8208 11.1444 11.7966 11.4107 11.5303C11.7036 11.2374 11.7036 10.7626 11.4107 10.4697L6.94103 6L11.4107 1.53033L11.4833 1.44621C11.7012 1.1526 11.677 0.735936 11.4107 0.46967Z"
      fill="#0E1114"
    />
  </svg>
);

export default Close;
