import {
  clearObjectStore,
  getAllObjectsFromDatabaseStore,
} from 'services/image-state-service';

const dispatchCustomImageEvent = (name, detail) => {
  const event = new CustomEvent(name, {
    detail,
  });
  window.dispatchEvent(event);
};

export const handleImageDownload = async downloadMainImageCb => {
  const downloadQueue = await getAllObjectsFromDatabaseStore({
    storeName: 'downloadQueue',
  });

  if (downloadQueue.length > 0) {
    const { data } = downloadQueue[0];
    const { formatName, socialName, type } = data;
    await clearObjectStore({
      storeName: 'downloadQueue',
    });

    if (type === 'single') {
      if (formatName && socialName) {
        //  handle download of particular social image
        dispatchCustomImageEvent('socialImageDownloadInit', {
          formatName,
          socialName,
        });
      } else {
        //  handle download of the main image at the top of editor
        downloadMainImageCb();
      }
    } else {
      dispatchCustomImageEvent('stickyBarDownloadInit', {
        type,
      });
    }
  }
};
