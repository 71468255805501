export const MAX_INPUT_FILE_SIZE = 20971520;

export const SUPPORTED_MIME_TYPES = ['image/jpeg', 'image/png'];

export const MAX_IMAGE_DIMENSIONS = 7000;

export const MIN_IMAGE_DIMENSIONS = 75;

export const EXTENSION_TO_DOWNLOAD = 'jpeg';

export const EXTENSION_TO_CONVERT = 'image/jpeg';

export const URL_TO_SHARE = 'https://goo.gl/JcucLp';

export const IMPORT_FROM_URL_MISTAKE =
  'We could not upload your image. Please check the URL or the file type (png and jpeg only).';

export const IMAGE_EDITOR_MOUNTING_DELAY = 500;

export const EDITED_IMAGE_ID = 'editedImage';

export const ZIP_ARCHIVE_NAME = 'Promo Resized Images';

export const PROD_LINK = 'https://promo.com/tools/image-resizer';

export const TURN_INTO_VIDEO_BASE_LINK =
  '/create-from-local-storage-photo/';
