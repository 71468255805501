import React from 'react';
import propTypes from 'prop-types';

import styles from './index.module.scss';

const Footer = ({ handleDownload, closeWidget }) => (
  <div className={styles.mobileFooter}>
    <button type="button" onClick={handleDownload}>
      Download
    </button>
    <button type="button" onClick={closeWidget}>
      Done
    </button>
  </div>
);

Footer.propTypes = {
  handleDownload: propTypes.func.isRequired,
  closeWidget: propTypes.func.isRequired,
};

export default Footer;
