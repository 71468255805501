import React, { Component } from 'react';
import propTypes from 'prop-types';
import imageResizer from 'utils/imageResizer';
import { actions, connect } from 'store';

class LazyImage extends Component {
  state = {
    loaded: false,
    error: false,
    src: null,
  };

  componentDidMount() {
    const {
      originalWidth,
      originalHeight,
      timeout,
      isLoadingFromDb,
    } = this.props;
    setTimeout(() => {
      const img = new Image(
        parseFloat(originalWidth),
        parseFloat(originalHeight),
      );
      const {
        social,
        id,
        src,
        resizeTo: { width, height },
      } = this.props;
      img.src = src;
      img.onload = () => {
        const image = imageResizer(
          img,
          parseFloat(originalWidth),
          parseFloat(originalHeight),
          width,
          height,
        );
        this.setState({
          loaded: true,
          src: image,
        });
        actions.setImageToThumbnails({ id, social, source: image, indexedDb: !isLoadingFromDb });
        actions.countProgress();
      };
      img.onerror = () => {
        this.setState({
          error: true,
        });
        actions.countProgress();
      };
    }, timeout);
  }

  shouldComponentUpdate(nextProps) {
    return !!nextProps.newImage;
  }

  render() {
    const { className, style, unloadedSrc, alt, newImage } = this.props;
    const { src, error, loaded } = this.state;
    if (error || !loaded) {
      return (
        <img className={className} style={style} src={unloadedSrc} alt={alt} />
      );
    }
    if (newImage) {
      return (
        <img className={className} style={style} src={newImage} alt={alt} />
      );
    }
    return <img className={className} style={style} src={src} alt={alt} />;
  }
}

LazyImage.defaultProps = {
  className: '',
  style: {},
  newImage: '',
};

LazyImage.propTypes = {
  className: propTypes.string,
  style: propTypes.object,
  alt: propTypes.string.isRequired,
  newImage: propTypes.string,
};

const mapStateToProps = ({ isLoadingFromDb }) => ({
  isLoadingFromDb,
});

export default connect(mapStateToProps)(LazyImage);
