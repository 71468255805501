import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import Cropper from 'react-cropper-axon';
import Slider from 'react-rangeslider';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import classnames from 'classnames';
import { connect, actions } from 'store';
import {
  EXTENSION_TO_CONVERT,
  IMAGE_EDITOR_MOUNTING_DELAY,
} from 'constants/index';
import calculateMaxScale from 'utils/imageResizer/utils/calculateMaxScale';
import getScaledCanvas from 'utils/imageResizer/utils/getScaledCanvas';
import trackEvent from 'utils/promoReportingWrapper';
import setImageToLocalStorage from 'utils/setImageToLocalStorage';
import { FlipIcon, MoveIcon } from 'components/EditorActionButtons';
import Close from 'assets/icons/Close';
import withDownloading from 'hocs/withDownloading';
import PanIcon from 'assets/icons/Pan';
import Description from './components/Description';
import MobileFooter from './components/MobileFooter';

import styles from './index.module.scss';
import 'cropperjs/dist/cropper.css';
import './cropper.css';

const EDITOR_CONTAINER_SIZE = { width: 482, height: 289 };
const ZOOM_PARAMS = { max: 4, min: 0, step: 0.01 };
const MOBILE_EDITOR_CONTAINER_SIZE = { width: '100%', height: 289 };

class ImageEditor extends Component {
  state = {
    image: null,
    zoomScale: 0,
    sliderScale: 0,
    minScale: 0,
    currentFlip: false,
    touched: false,
    lastScrollPosition: 0,
    displayPanIcon: true,
    panIconSizes: {
      width: 0,
      height: 0,
    },
  };

  debouncedTrack = debounce(
    pagePlatform =>
      trackEvent('IMR zoom clicked', {
        pagePlatform,
        buttonSection: 'widget',
      }),
    200,
  );

  handleMouseMouve = throttle(event => {
    if (this.isCursorInCenter(event)) {
      this.setState({ displayPanIcon: false });
    } else {
      this.setState({ displayPanIcon: true });
    }
  }, 100);

  componentWillMount() {
    this.editorWidgetRef = React.createRef();
  }

  componentDidMount() {
    try {
      MOBILE_EDITOR_CONTAINER_SIZE.width = window.innerWidth - 32;
    } catch (e) {
      /* Just do nothing */
    }
    document.body.style.overflow = 'hidden';
    const { pagePlatform } = this.props;
    this.fitImageToRightDementions();

    if (pagePlatform === 'desktop') {
      this.scrollToComponent();
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  setPanIconRef = ref => {
    this.panIcon = ref;

    if (!this.panIcon) return;

    const panIconBounds = this.panIcon.getBoundingClientRect();
    this.setState({
      panIconSizes: {
        width: panIconBounds.width,
        height: panIconBounds.height,
      },
    });
  };

  scrollToComponent = () => {
    const editorWidget = this.editorWidgetRef.current;
    setTimeout(() => {
      if (this.editorWidgetRef) {
        this.setState({
          lastScrollPosition: -document.body.getBoundingClientRect().top,
        });

        window.scrollTo({
          top:
            editorWidget.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            20,
          behavior: 'smooth',
        });
      }
    }, IMAGE_EDITOR_MOUNTING_DELAY);
  };

  fitImageToRightDementions = () => {
    const {
      formatSize,
      originalWidth,
      originalHeight,
      mainCroppedImage,
    } = this.props;
    const { width: newWidth, height: newHeight } = formatSize;
    if (
      newWidth > parseFloat(originalWidth) ||
      newHeight > parseFloat(originalHeight)
    ) {
      this.setResizedImageToState(
        mainCroppedImage,
        newWidth,
        newHeight,
        originalWidth,
        originalHeight,
      );
    } else {
      this.setState({ image: mainCroppedImage });
    }
  };

  flipImage = () => {
    const { pagePlatform } = this.props;
    const { currentFlip } = this.state;
    if (!currentFlip) {
      this.editorRef.scale(-1, 1);
    } else {
      this.editorRef.scale(1, 1);
    }
    this.setState(prevState => ({ currentFlip: !prevState.currentFlip }));

    trackEvent('IMR flip image clicked', {
      pagePlatform,
      buttonSection: 'widget',
    });
  };

  turnIntoVideo = () => {
    const {
      formatSize: { width, height },
      token,
    } = this.props;
    const image = this.editorRef
      .getCroppedCanvas({ width, height })
      .toDataURL(EXTENSION_TO_CONVERT);
    trackEvent('IMR turn into video clicked', {
      buttonSection: 'widget',
    });
    setImageToLocalStorage(image, token);
  };

  handleDownload = () => {
    const {
      pagePlatform,
      socialName,
      formatName,
      formatSize,
      downloadImage,
    } = this.props;
    const { width, height } = formatSize;
    const { currentFlip, image: originalImage } = this.state;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    const data = this.editorRef.getData();
    const { x, y, width: croppedWidth, height: croppedHeight } = data;
    img.onload = () => {
      canvas.width = width;
      canvas.height = height;
      const maxScale = calculateMaxScale(width, height, img.width, img.height);
      const scaledCanvas = getScaledCanvas(
        img,
        img.width,
        img.height,
        maxScale,
        currentFlip,
      );
      context.drawImage(
        scaledCanvas,
        Math.floor(Math.floor(x * maxScale)),
        Math.floor(Math.floor(y * maxScale)),
        Math.floor(croppedWidth.toFixed(0) * maxScale),
        Math.floor(croppedHeight.toFixed(0) * maxScale),
        0,
        0,
        Math.floor(width),
        Math.floor(height),
      );
      const image = canvas;
      const convertedImage = image.toDataURL(`${EXTENSION_TO_CONVERT}`);
      downloadImage({ image: convertedImage, socialName, formatName });
    };
    img.src = originalImage;

    trackEvent('IMR image downloaded button clicked', {
      buttonSection: 'widget',
      countOfImages: 1,
      isMulti: false,
      pagePlatform,
    });

    trackEvent('IMR image downloaded successfully', {
      countOfImages: 1,
      isMulti: false,
      buttonSection: 'widget',
      categoryTypeArray: [socialName],
      imageNameArray: [`${socialName} ${formatName}`],
      pagePlatform,
    });
  };

  setEditorRef = editorRef => {
    if (editorRef) {
      this.editorRef = editorRef.cropper;
    }
  };

  updateScale = (scale, type) => {
    const { pagePlatform } = this.props;
    const { minScale } = this.state;
    if (type === 'wheel') {
      let { ratio } = scale.detail;
      if (ratio > ZOOM_PARAMS.max) {
        ratio = 4;
        scale.preventDefault();
      }
      if (ratio < minScale) {
        scale.preventDefault();
      }
      this.setState({ sliderScale: parseFloat(ratio) });
    } else {
      this.debouncedTrack(pagePlatform);
      this.setState({
        sliderScale: parseFloat(scale),
        zoomScale: parseFloat(scale),
      });
    }
  };

  setResizedImageToState = (
    imageSrc,
    newWidth,
    newHeight,
    originalWidth,
    originalHeight,
  ) => {
    const img = new Image(
      parseFloat(originalWidth),
      parseFloat(originalHeight),
    );
    const maxScale = calculateMaxScale(
      newWidth,
      newHeight,
      parseFloat(originalWidth),
      parseFloat(originalHeight),
    ).toFixed(3);
    img.onload = () => {
      const resizedImage = getScaledCanvas(
        img,
        parseFloat(originalWidth),
        parseFloat(originalHeight),
        maxScale,
      ).toDataURL(EXTENSION_TO_CONVERT);
      this.setState({ image: resizedImage });
    };
    img.src = imageSrc;
  };

  handleOpenMobilePopup = () => {
    const { onClose } = this.props;

    actions.openPopup({
      type: 'default',
      isOpen: true,
      title: 'Discard Changes?',
      description: ['Are you sure you want to discard your edits?'],
      buttons: {
        blueButton: {
          text: 'Yes',
          handler: () => {
            onClose();
            actions.closePopup();
          },
        },
        whiteButton: {
          text: 'No',
          handler: actions.closePopup,
        },
      },
    });
  };

  closeWidget = () => {
    const { onClose, id, socialName, formatSize, pagePlatform } = this.props;
    const {
      currentFlip,
      lastScrollPosition,
      image: originalImage,
    } = this.state;
    const { width, height } = formatSize;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    const data = this.editorRef.getData();
    const { x, y, width: croppedWidth, height: croppedHeight } = data;
    img.onload = () => {
      canvas.width = width;
      canvas.height = height;
      const maxScale = calculateMaxScale(width, height, img.width, img.height);
      const scaledCanvas = getScaledCanvas(
        img,
        img.width,
        img.height,
        maxScale,
        currentFlip,
      );
      context.drawImage(
        scaledCanvas,
        Math.floor(Math.floor(x * maxScale)),
        Math.floor(Math.floor(y * maxScale)),
        Math.floor(croppedWidth.toFixed(0) * maxScale),
        Math.floor(croppedHeight.toFixed(0) * maxScale),
        0,
        0,
        Math.floor(width),
        Math.floor(height),
      );
      const image = canvas.toDataURL('image/jpeg');
      actions.setImageToThumbnails({ id, social: socialName, source: image });
    };
    img.src = originalImage;

    if (pagePlatform === 'desktop') {
      window.scrollTo({
        top: lastScrollPosition,
        behavior: 'smooth',
      });
    }

    if (pagePlatform === 'mobile') {
      trackEvent('IMR apply clicked in widget', {
        pagePlatform,
      });
    }

    onClose();
  };

  afterImageReady = (newWidth, newHeight) => {
    this.editorRef.zoom(0);
    const canvasData = this.editorRef.getCanvasData();
    const { width, naturalWidth, naturalHeight } = canvasData;

    if (naturalWidth < naturalHeight) {
      const data = this.editorRef.getCropBoxData();
      // fix for image default position
      if (newWidth > newHeight || newWidth === newHeight) {
        data.left = 0;
        data.top = 0;
        this.editorRef.setCropBoxData(data);
      }
    }
    const zoomRatio = width / naturalWidth;
    this.setState({
      zoomScale: parseFloat(zoomRatio),
      minScale: parseFloat(zoomRatio),
    });
  };

  getMobileWidth = () => {
    const style = window.getComputedStyle(this.editorWidgetRef.current);
    const width = parseInt(style.width, 10);

    return width - 32;
  };

  isCursorInCenter = event => {
    const { panIconSizes } = this.state;

    if (!this.editorRef || !this.editorRef.containerData) {
      return;
    }

    const bounds = this.cropperContainer.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;

    const containerWidth = this.editorRef.containerData.width;
    const containerHeight = this.editorRef.containerData.height;

    const panIconWidth = panIconSizes.width;
    const panIconHeight = panIconSizes.height;

    const halfOfContainerWidth = containerWidth / 2;
    const halfOfIconWidth = panIconWidth / 2;

    const halfOfContainerHeight = containerHeight / 2;
    const halfOfIconHeight = panIconHeight / 2;

    if (
      x > halfOfContainerWidth - halfOfIconWidth &&
      x < halfOfContainerWidth + halfOfIconWidth &&
      (y > halfOfContainerHeight - halfOfIconHeight &&
        y < halfOfContainerHeight + halfOfIconHeight)
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      socialName,
      formatName,
      formatSize,
      raw: { formatDescription },
      className,
      pagePlatform,
    } = this.props;
    const { width: newWidth, height: newHeight } = formatSize;
    const {
      image,
      zoomScale,
      sliderScale,
      touched,
      minScale,
      displayPanIcon,
    } = this.state;

    return (
      <div className={styles.imageEditorPopup}>
        <div className={styles.imageEditorPopupInner}>
          <div
            ref={this.editorWidgetRef}
            className={classnames(styles.imageEditor, styles[className])}
          >
            <button
              type="button"
              className={styles.close}
              onClick={
                pagePlatform === 'desktop'
                  ? this.closeWidget
                  : this.handleOpenMobilePopup
              }
            >
              <Close />
            </button>
            <Description
              formatName={formatName}
              socialName={socialName}
              formatSize={formatSize}
              turnIntoVideo={this.turnIntoVideo}
              handleDownload={this.handleDownload}
            />
            <div className={styles.editorWrapperV2}>
              <div className={styles.editorWrapperContainer}>
                <div className={styles.editorWrapper}>
                  {!touched && (
                    <div className={styles.mobileLabel}>
                      <PanIcon />
                      <p>Pinch and drag to start editing</p>
                    </div>
                  )}
                  {image && (
                    <Fragment>
                      <div
                        className={styles.cropperWrapper}
                        onMouseMove={event => {
                          event.persist();
                          this.handleMouseMouve(event);
                        }}
                        ref={ref => (this.cropperContainer = ref)}
                      >
                        <Cropper
                          ref={this.setEditorRef}
                          src={image}
                          style={
                            pagePlatform === 'mobile'
                              ? MOBILE_EDITOR_CONTAINER_SIZE
                              : EDITOR_CONTAINER_SIZE
                          }
                          key={`${socialName}-${formatName}`}
                          aspectRatio={newWidth / newHeight}
                          viewMode={1}
                          modal
                          center={false}
                          toggleDragModeOnDblclick={false}
                          background={false}
                          autoCropArea={1}
                          zoomTo={zoomScale}
                          wheelZoomRatio={ZOOM_PARAMS.step}
                          zoom={ev => {
                            if (
                              ev.detail.originalEvent instanceof WheelEvent ||
                              ev.detail.originalEvent instanceof TouchEvent
                            ) {
                              this.updateScale(ev, 'wheel');
                              this.debouncedTrack(pagePlatform);
                            }
                          }}
                          className={styles.editorWidgetEdit}
                          minCropBoxWidth={
                            pagePlatform === 'mobile'
                              ? this.getMobileWidth()
                              : EDITOR_CONTAINER_SIZE.width
                          }
                          minContainerWidth={
                            pagePlatform === 'mobile'
                              ? this.getMobileWidth()
                              : EDITOR_CONTAINER_SIZE.width
                          }
                          minCanvasWidth={
                            pagePlatform === 'mobile'
                              ? this.getMobileWidth()
                              : EDITOR_CONTAINER_SIZE.width
                          }
                          minCanvasHeight={EDITOR_CONTAINER_SIZE.height}
                          guides={false}
                          dragMode="move"
                          movable
                          cropBoxMovable={false}
                          cropBoxResizable={false}
                          ready={() => {
                            this.afterImageReady(newWidth, newHeight);
                          }}
                          crop={() => this.setState({ displayPanIcon: false })}
                          cropstart={() => {
                            this.setState({ displayPanIcon: false });
                            trackEvent('IMR pan image', {
                              pagePlatform,
                              buttonSection: 'widget',
                            });
                          }}
                          cropend={() =>
                            this.setState({ displayPanIcon: true })
                          }
                        />
                        <button
                          type="button"
                          ref={this.setPanIconRef}
                          className={styles.moveImage}
                          style={
                            !displayPanIcon
                              ? { display: 'none' }
                              : { display: 'block' }
                          }
                        >
                          <MoveIcon />
                        </button>
                        <button
                          type="button"
                          className={styles.flipImage}
                          data-rh="Horizontal Flip"
                          onClick={this.flipImage}
                        >
                          <FlipIcon />
                        </button>
                      </div>
                      <div className={styles.slider}>
                        <Slider
                          onChange={this.updateScale}
                          value={sliderScale}
                          min={minScale}
                          step={ZOOM_PARAMS.step}
                          max={ZOOM_PARAMS.max}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className={styles.descriptionV2}>
                <div className={styles.descriptionEditor}>
                  <div>
                    <p className={styles.descriptionTitle}>{formatName}</p>
                    <p className={styles.formatDimensions}>{`${
                      formatSize.width
                    } x ${formatSize.height}`}</p>
                    <p
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: formatDescription }}
                    />
                  </div>
                  <div className={styles.buttonsWrapper}>
                    <button type="button" onClick={this.handleDownload}>
                      Download
                    </button>
                    <button
                      type="button"
                      onClick={this.turnIntoVideo}
                      data-rh="Upload your photo into Promo.com’s video maker and easily convert it to video"
                    >
                      Convert to Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <MobileFooter
              handleDownload={this.handleDownload}
              closeWidget={this.closeWidget}
            />
            <div className={styles.mobileDescription}>
              <div className={styles.mobileDescriptionTitle}>
                {this.props.formatName}
              </div>
              <div className={styles.mobileDescriptionSize}>
                {this.props.formatSize.width} x {this.props.formatSize.height}
              </div>
              <div className={styles.mobileDescriptionText}>
                {this.props.raw.formatDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  pagePlatform,
  mainCroppedImage,
  originalImageDimensions,
  token,
}) => ({
  pagePlatform,
  mainCroppedImage,
  token,
  originalImageDimensions,
});

ImageEditor.propTypes = {
  pagePlatform: propTypes.string.isRequired,
  originalImageDimensions: propTypes.shape({
    x: propTypes.number,
    y: propTypes.number,
  }).isRequired,
  token: propTypes.string.isRequired,
  mainCroppedImage: propTypes.string.isRequired,
};

export default connect(mapStateToProps)(withDownloading(ImageEditor));
