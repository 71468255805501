const getScaledCanvas = (
  imgObj,
  originalWidth,
  originalHeight,
  maxScale,
  flipped,
) => {
  const scaledCanvas = document.createElement('canvas');
  const scaledContext = scaledCanvas.getContext('2d');
  scaledCanvas.width = Math.round(originalWidth * maxScale);
  scaledCanvas.height = Math.round(originalHeight * maxScale);
  if (flipped) {
    scaledContext.translate(scaledCanvas.width, 0);
    scaledContext.scale(-1, 1);
  }
  scaledContext.drawImage(
    imgObj,
    0,
    0,
    Math.round(originalWidth * maxScale),
    Math.round(originalHeight * maxScale),
  );
  return scaledCanvas;
};

export default getScaledCanvas;
