import React from 'react';

const Loader = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.25"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12.0709C24 5.44344 18.6274 0.0708618 12 0.0708618C5.37258 0.0708618 0 5.44344 0 12.0709C0 18.6983 5.37258 24.0709 12 24.0709C18.6274 24.0709 24 18.6983 24 12.0709ZM2 12.0709C2 6.54801 6.47715 2.07086 12 2.07086C17.5228 2.07086 22 6.54801 22 12.0709C22 17.5937 17.5228 22.0709 12 22.0709C6.47715 22.0709 2 17.5937 2 12.0709Z"
      fill="#FF3366"
    />
    <path
      d="M11.567 0C14.6673 0 17.8198 0.940944 19.9685 3.17615C20.3781 3.60218 20.3781 4.2929 19.9685 4.71893C19.559 5.14495 18.895 5.14495 18.4854 4.71893C16.7261 2.88884 14.1053 2.18182 11.567 2.18182C6.35438 2.18182 2.12867 6.57757 2.12867 12C2.12867 17.4224 6.35438 21.8182 11.567 21.8182C12.1462 21.8182 12.6158 22.3066 12.6158 22.9091C12.6158 23.5116 12.1462 24 11.567 24C5.196 24 0.03125 18.6274 0.03125 12C0.03125 5.37258 5.196 0 11.567 0Z"
      fill="#FF3366"
    />
  </svg>
);

export default Loader;
