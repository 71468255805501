import React, { Component } from 'react';
import propTypes from 'prop-types';
import debounce from 'lodash.debounce';

import styles from './InputWithLabel.module.scss';

class InputWithLabel extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value, error: null };
    this.delayedCallback = debounce(val => {
      this.props.onChange({ target: { value: val } });
    }, 1000);
  }

  componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  handleInputChange = event => {
    event.persist();
    const {
      target: { value: nextValue },
    } = event;
    const { val, cancelOnChange = false } =
      typeof nextValue !== 'number' && !nextValue
        ? { val: nextValue, cancelOnChange: true }
        : { val: Number(nextValue) };
    this.setState({ value: val, error: null });
    if (!cancelOnChange) {
      this.delayedCallback(val);
    }
  };

  render() {
    const {
      title,
      disabled,
      placeholder,
      round,
      measure,
      ...rest
    } = this.props;

    const { value, error } = this.state;
    const nValue = disabled
      ? ''
      : typeof value === 'number' && round
        ? Math.round(value)
        : value;

    return (
      <div className={disabled && styles.disabledInputWrapper}>
        <div className={styles.inputWithLabel}>
          <p className={styles.inputWithLabelTitle}>{title}</p>
          <div className={styles.inputWrapper}>
            <input
              {...rest}
              onChange={this.handleInputChange}
              disabled={disabled}
              className={styles.inputWithLabelInput}
              placeholder={disabled ? placeholder : ''}
              value={nValue}
            />
            {measure && <span className={styles.inputMeasure}>{measure}</span>}
          </div>
          {error && <p>{error}</p>}
        </div>
      </div>
    );
  }
}

InputWithLabel.propTypes = {
  title: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  measure: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
};

export default InputWithLabel;
