import React, { Component } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import { EDITED_IMAGE_ID } from 'constants';
import { actions, connect } from 'store';
import trackEvent from 'utils/promoReportingWrapper';
import Checked from 'assets/icons/Checked';
import setImageToLocalStorage from 'utils/setImageToLocalStorage';
import image from 'assets/Pattern_image.jpg';
import VideoIcon from 'assets/video-icon';
import DownloadIcon from 'assets/download-icon';
import LazyLoadImg from './components/LazyLoadImg';

import styles from './index.module.scss';

const THUMBNAIL_WIDTH = 260;

class MainImage extends Component {
  componentWillReceiveProps(nextProps) {
    const { onEditedImageUpdate, editedImage, socialName, i } = nextProps;
    if (this.isCurrentImageEditing(editedImage, socialName, i)) {
      onEditedImageUpdate();
    }
  }

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  selectImage = (socialName, socialObj) => {
    const { pagePlatform, thumbnails } = this.props;
    const { id: imageId } = socialObj;
    const img = thumbnails[socialName].find(soc => soc.id === imageId);
    trackEvent('IMR select images check box clicked', {
      categoryType: socialName,
      isMulti: false,
      value: !img.selected,
      pagePlatform,
    });
    actions.setSelected({ socialName, imageId });
  };

  turnIntoVideo = (categoryType, imageName, img) => {
    const { token } = this.props;
    trackEvent('IMR turn into video clicked', {
      buttonSection: 'thumbnail',
      categoryType,
      imageName,
    });
    setImageToLocalStorage(img, token);
  };

  isCurrentImageEditing = (editedImage, socialName, i) =>
    editedImage &&
    editedImage.socialName === socialName &&
    editedImage.index === i;

  render() {
    const {
      editedImage,
      socialObj,
      originalImage,
      contentWrapperWidth,
      index,
      i,
      onEditClicked,
      downloadImage,
      socialName,
      imageRef,
      pagePlatform,
      originalImageDimensions,
    } = this.props;
    return (
      <div className={styles.imageWithActionsWrapper}>
        <span className={styles.imageTag}>
          {`${socialObj.formatSize.width} x ${socialObj.formatSize.height}`}
        </span>
        <div
          ref={imageRef}
          id={
            this.isCurrentImageEditing(editedImage, socialName, i)
              ? EDITED_IMAGE_ID
              : ''
          }
          data-social={socialName}
          className={classnames(styles.imageWrapper, {
            [styles.editing]:
              editedImage &&
              editedImage.socialName === socialName &&
              editedImage.index === i,
            [styles.selected]: socialObj.selected,
          })}
          style={{
            width: `${THUMBNAIL_WIDTH}px`,
            height:
              pagePlatform === 'desktop'
                ? `${(THUMBNAIL_WIDTH * socialObj.formatSize.height) /
                    socialObj.formatSize.width}px`
                : !originalImage.source
                  ? `${(contentWrapperWidth * socialObj.formatSize.height) /
                      socialObj.formatSize.width}px`
                  : false,
          }}
        >
          {!originalImage.source &&
          !originalImage.width &&
          !originalImage.height ? (
            <img src={image} alt="thumbnail" />
          ) : (
            <LazyLoadImg
              src={originalImage.source}
              originalHeight={originalImageDimensions.originalHeight}
              originalWidth={originalImageDimensions.originalWidth}
              social={socialName}
              id={socialObj.id}
              unloadedSrc={image}
              timeout={index * 100 * i}
              newImage={socialObj.source}
              resizeTo={{
                width: socialObj.formatSize.width,
                height: socialObj.formatSize.height,
              }}
              alt={`Promo ${socialObj.formatName}`}
            />
          )}
          {originalImage.source ? (
            <div className={styles.onHoverContainer}>
              <button
                className={styles.hoverEditButton}
                type="button"
                onClick={() =>
                  onEditClicked(
                    socialName,
                    socialObj.formatName,
                    socialObj.formatSize,
                    i,
                    socialObj.id,
                    socialObj,
                  )
                }
              >
                Edit
              </button>
              <button
                className={styles.hoverDownloadButton}
                type="button"
                onClick={() => downloadImage(socialObj, socialName)}
              >
                Download
              </button>
            </div>
          ) : (
            <div className={styles.disabledContainer}>
              <button type="button" onClick={this.goToTop}>
                Upload
              </button>
            </div>
          )}
          <div className={styles.selectImageWrapper}>
            <button
              type="button"
              className={!originalImage.source ? styles.disabled : ''}
              onClick={() => this.selectImage(socialName, socialObj)}
            >
              {socialObj.selected && <Checked />}
            </button>
          </div>
        </div>
        {originalImage.source && (
          <div className={styles.buttonsWrapper}>
            <button
              type="button"
              data-rh="Upload your photo into our video maker, Promo and easily turn it into video"
              onClick={() =>
                this.turnIntoVideo(
                  socialName,
                  socialObj.formatName,
                  socialObj.source,
                )
              }
            >
              <VideoIcon />
              Convert to video
            </button>
            <button
              type="button"
              onClick={() => downloadImage(socialObj, socialName)}
            >
              <DownloadIcon />
              Download
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ pagePlatform, thumbnails, token }) => ({
  pagePlatform,
  thumbnails,
  token,
});

MainImage.propTypes = {
  pagePlatform: propTypes.string.isRequired,
  thumbnails: propTypes.object.isRequired,
  token: propTypes.string.isRequired,
};

export default connect(mapStateToProps)(MainImage);
