import React from 'react';
import * as Icons from 'assets/icons/SharingSocials';
import { PROD_LINK } from 'constants';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

const getIcon = icon => {
  const Icon = Icons[icon];
  return <Icon />;
};

const URL_TO_SHARE = `${PROD_LINK}?utm_campaign=image-resizer-share`;

const icons = {
  Facebook: ({ onClick, className }) => (
    <div onClick={onClick} className={className}>
      <FacebookShareButton
        quote="Check out the Free Image Resizer by @promodotcom
      Resize your images to perfectly fit social media and web with the click of a button."
        url={URL_TO_SHARE}
      >
        {getIcon('Facebook')}
      </FacebookShareButton>
    </div>
  ),
  Twitter: ({ onClick, className }) => (
    <div onClick={onClick} className={className}>
      <TwitterShareButton
        title="Check out the Free Image Resizer by @Promodotcom.
  Resize your images to perfectly fit social media and web with the click of a button."
        url={URL_TO_SHARE}
      >
        {getIcon('Twitter')}
      </TwitterShareButton>
    </div>
  ),
  Linkedin: ({ onClick, className }) => (
    <div onClick={onClick} className={className}>
      <LinkedinShareButton
        title="Check out the Free Image Resizer by @Promodotcom.
    Resize your images to perfectly fit social media and web with the click of a button."
        description="Check out the Free Image Resizer by @Promodotcom.
    Resize your images to perfectly fit social media and web with the click of a button."
        url={URL_TO_SHARE}
      >
        {getIcon('Linkedin')}
      </LinkedinShareButton>
    </div>
  ),
  Email: ({ onClick, className }) => (
    <div onClick={onClick} className={className}>
      <EmailShareButton
        subject="You’ve got to see this New Image Resizer"
        body={`Hi friend!
  I found a free image resizer by Promo and it already made my life easier.

  It takes your image and optimizes the size for all social media and web formats with a click of a button.

  Check it out, I think it will be super useful for you.

  ${URL_TO_SHARE}
  `}
        url={URL_TO_SHARE}
      >
        {getIcon('EmailAndBlog')}
      </EmailShareButton>
    </div>
  ),
};

export default icons;
