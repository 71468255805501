import React, { Component } from 'react';
import saveAs from 'file-saver';
import { EXTENSION_TO_DOWNLOAD } from 'constants/';
import base64ToFile from 'utils/base64ToFile';
import { connect, actions } from 'store';
import {
  clearObjectStore,
  putObjectInDatabase,
} from 'services/image-state-service';
import { userService } from 'services/user-session-service';

function withDownloading(WrappedComponent) {
  class WithDownloading extends Component {
    downloadImage = async ({ image, socialName, formatName, imageName }) => {
      await clearObjectStore({
        storeName: 'downloadQueue',
      });
      const imgName =
        imageName ||
        `Promo-${socialName}-${formatName}.${EXTENSION_TO_DOWNLOAD}`;

      const userInfo = await userService.getUser();
      if (userInfo) {
        const { pagePlatform } = this.props;

        if (navigator.userAgent.match('CriOS') && pagePlatform === 'mobile') {
          const win = window.open();
          win.document.write(
            `<iframe id='myIframe' width='600' height='315' src='${image}' frameborder='0' allowfullscreen ></iframe>`,
          );
          win.document.close();
        } else {
          saveAs(base64ToFile(image, imgName));
        }
      } else {
        await putObjectInDatabase({
          storeName: 'downloadQueue',
          name: 'downloadQueue',
          data: {
            type: 'single',
            socialName,
            formatName,
          },
        });
        actions.openPopup({ type: 'signup', isOpen: true });
      }
    };

    render() {
      return (
        <WrappedComponent downloadImage={this.downloadImage} {...this.props} />
      );
    }
  }

  const mapStateToProps = ({ pagePlatform, thanksPopUpShowed }) => ({
    pagePlatform,
    thanksPopUpShowed,
  });

  return connect(mapStateToProps)(WithDownloading);
}

export default withDownloading;
