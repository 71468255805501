import React, { useEffect } from 'react';
import { connect } from 'store';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'assets/loader';

import styles from './index.module.scss';

const LoaderPopup = ({ title, description, onClose, isLoadingFromDb }) => {
  useEffect(
    () => {
      if (!isLoadingFromDb) {
        onClose();
      }
    },
    [isLoadingFromDb],
  );
  return (
    <div className={styles.loaderPopup}>
      <div className={styles.content}>
        <Loader />
        <h3
          className={classnames(
            styles.text,
            styles.title,
            'text-headline-medium',
          )}
        >
          {title}
        </h3>
        <p
          className={classnames(
            styles.text,
            styles.subtitle,
            'text-title-medium',
          )}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

LoaderPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isLoadingFromDb: PropTypes.bool.isRequired,
};

LoaderPopup.defaultProps = {
  title: 'Rendering your files',
  description: 'Please wait for the action to be completed',
};

const mapStateToProps = ({ isLoadingFromDb }) => ({
  isLoadingFromDb,
});

export default connect(mapStateToProps)(LoaderPopup);
