import React from 'react';

const Pan = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71291 0.973535C9.62445 1.01013 9.54158 1.06439 9.46967 1.1363L6.96967 3.6363C6.67678 3.92919 6.67678 4.40406 6.96967 4.69696C7.26256 4.98985 7.73744 4.98985 8.03033 4.69696L9.25 3.47729V9.25H3.47732L4.69699 8.03033C4.98988 7.73744 4.98988 7.26256 4.69699 6.96967C4.40409 6.67678 3.92922 6.67678 3.63633 6.96967L1.13633 9.46967C0.98988 9.61612 0.916656 9.80806 0.916656 10C0.916656 10.1017 0.936896 10.1987 0.973566 10.2871C1.0099 10.3749 1.06362 10.4572 1.13474 10.5287L1.13633 10.5303L4.69699 8.03033L1.13672 10.5307L3.63633 13.0303C3.92922 13.3232 4.40409 13.3232 4.69699 13.0303C4.98988 12.7374 4.98988 12.2626 4.69699 11.9697L3.47732 10.75H9.25V16.5227L8.03033 15.303C7.73744 15.0102 7.26256 15.0102 6.96967 15.303C6.67678 15.5959 6.67678 16.0708 6.96967 16.3637L9.46967 18.8637C9.76256 19.1566 10.2374 19.1566 10.5303 18.8637L13.0303 16.3637C13.3232 16.0708 13.3232 15.5959 13.0303 15.303C12.7374 15.0102 12.2626 15.0102 11.9697 15.303L10.75 16.5227V10.75H16.5227L15.303 11.9697C15.0101 12.2626 15.0101 12.7374 15.303 13.0303C15.5959 13.3232 16.0708 13.3232 16.3637 13.0303L18.8637 10.5303C18.9419 10.4521 18.9992 10.3609 19.0357 10.2637C19.0582 10.2035 19.0728 10.1411 19.0793 10.0779C19.0808 10.064 19.0818 10.05 19.0825 10.036C19.0835 10.0144 19.0836 9.99286 19.0828 9.97131C19.0822 9.95689 19.0813 9.94248 19.0799 9.92811C19.0737 9.86308 19.059 9.79871 19.0358 9.73679C18.9994 9.63937 18.942 9.54801 18.8637 9.46967L16.3637 6.96967C16.0708 6.67678 15.5959 6.67678 15.303 6.96967C15.0101 7.26256 15.0101 7.73744 15.303 8.03033L16.5227 9.25H10.75V3.47729L11.9697 4.69696C12.2626 4.98985 12.7374 4.98985 13.0303 4.69696C13.3232 4.40406 13.3232 3.92919 13.0303 3.6363L10.5307 1.13669L10.5303 1.1363M10.5287 1.13471C10.3931 0.999918 10.2063 0.916626 10 0.916626C9.89831 0.916626 9.80134 0.936865 9.71291 0.973535"
      fill="#fff"
    />
  </svg>
);

export default Pan;
