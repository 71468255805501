import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import { connect } from 'store';
import trackEvent from 'utils/promoReportingWrapper';
import { URL_TO_SHARE } from 'constants';

import icons from 'constants/shareIcons';

import styles from './index.module.scss';

const CustomPopup = ({ description, shareSection, pagePlatform }) => (
  <Fragment>
    <div className={styles.description}>
      {description.map(text => (
        <p key={text}>{text}</p>
      ))}
    </div>
    <div className={styles.buttonsWrapper}>
      {Object.keys(icons).map((icon, i) => {
        const Icon = icons[icon];
        return (
          <Icon
            key={String(i)}
            className={styles.icon}
            onClick={() => {
              trackEvent('IMR share button clicked', {
                socialMedia: icon,
                shareSection,
                pagePlatform,
              });
            }}
          />
        );
      })}
    </div>
    <footer className={styles.footer}>
      <div className={styles.linkWrapper}>
        <p className={styles.label}>Easily share this link anywhere:</p>
        <div className={styles.linkField}>
          <p>{URL_TO_SHARE}</p>
          <button
            type="button"
            data-rh-click="Copied"
            onClick={() => {
              trackEvent('IMR copy link for sharing clicked', {
                shareSection,
                pagePlatform,
              });
              copy(URL_TO_SHARE);
            }}
          >
            Copy Link
          </button>
        </div>
      </div>
    </footer>
  </Fragment>
);

CustomPopup.propTypes = {
  description: PropTypes.arrayOf(PropTypes.any).isRequired,
  shareSection: PropTypes.string.isRequired,
  pagePlatform: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pagePlatform }) => ({
  pagePlatform,
});

export default connect(mapStateToProps)(CustomPopup);
