import {EXTENSION_TO_CONVERT} from 'constants/index'; // (set image output format, right now = 'image/jpeg')
import getVariant from './utils/getVariant';
import calculateMaxScale from './utils/calculateMaxScale';
import getCroppedCenterPoints from './utils/getCroppedCenterPoints';
import getCroppedTopPoints from './utils/getCroppedTopPoints';
import getScaledCanvas from './utils/getScaledCanvas';

// Main function to resize image
const imageResizer = (
  imgObj,
  originalWidth,
  originalHeight,
  newWidth,
  newHeight,
) => {
  // Setuping main image
  const mainCanvas = document.createElement('canvas');
  const mainContext = mainCanvas.getContext('2d', {alpha: false});
  mainCanvas.width = newWidth;
  mainCanvas.height = newHeight;
  // Getting variant of input and output images
  const outputVariant = getVariant(newWidth, newHeight);
  const inputVariant = getVariant(originalWidth, originalHeight);
  let variant = null;
  const maxScale = calculateMaxScale(
    newWidth,
    newHeight,
    originalWidth,
    originalHeight,
  );
  if (inputVariant === 'horizontal') {
    // Case horizontal image
    if (outputVariant === 'horizontal') {
      if (newWidth === originalWidth && newHeight < originalHeight) {
        // Horizontal - Same Width, Shorther
        // zoomin to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 1;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Horizontal - Wider, Taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 2;
      }
      if (newWidth > originalWidth && newHeight < originalHeight) {
        // Horizontal - Wider, Shorter
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 3;
      }
      if (newWidth < originalWidth && newHeight > originalHeight) {
        // Horizontal - Narrower, Taller
        // Zoomin to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 4;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Horizontal to bigger
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 5;
      }
      if (newWidth === originalWidth && newHeight > originalHeight) {
        // Horizontal same width, smaller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 6;
      }
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Horizontal same hight, wider
        // zoom to maxScale, crop center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 7;
      }
      if (newWidth === originalWidth && newHeight === originalHeight) {
        // Horizontal to horizontal, same width and height
        mainContext.drawImage(imgObj, 0, 0, originalWidth, originalHeight);
        variant = 8;
      }
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Horizontal to horizontal, narrower, same height
        // Crop in center
        const cropPoints = getCroppedCenterPoints(
          originalWidth,
          originalHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(imgObj, x1, y1, x2, y2, 0, 0, x2, y2);
        variant = 9;
      }
    }
    if (outputVariant === 'square') {
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Square - Same height
        // Zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 10;
      }
      if (newWidth < originalWidth && newHeight > originalHeight) {
        // Square - Taller
        // Zoomin to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 11;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Square - Shorter
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 12;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Square - smaller than original dementions
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 13;
      }
      if (newWidth === originalWidth && newHeight > originalHeight) {
        // Square - same width, taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 14;
      }
    }
    if (outputVariant === 'vertical') {
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Vertical - Taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 15;
      }
      if (newWidth < originalWidth && newHeight > originalHeight) {
        // Vertical - Shorter
        // crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 16;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Vertical - larger wider
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 17;
      }
      if (newWidth === originalWidth && newHeight > originalHeight) {
        // Horizontal to vertical
        // Zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 18;
      }
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Horizontal to vertical, narower, same height
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 19;
      }
    }
  }
  if (inputVariant === 'square') {
    // Case square image
    if (outputVariant === 'horizontal') {
      if (newWidth === originalWidth && newHeight < originalHeight) {
        // Horizontal - Same Width, Shorter
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 20;
      }
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Horizontal - Same Height, Wider
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 21;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Horizontal - bigger, wider
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 22;
      }
      if (newWidth > originalWidth && newHeight < originalHeight) {
        // Horizontal - wider, smaller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 23;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Horizontal - smaller, narrower
        // Zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 24;
      }
    }
    if (outputVariant === 'vertical') {
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Vertical - Same Height
        // crop in center
        const cropPoints = getCroppedCenterPoints(
          originalWidth,
          originalHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(imgObj, x1, y1, x2, y2, 0, 0, x2, y2);
        variant = 25;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Vertical - Shorter
        // zoom to maxscale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 26;
      }
      if (newWidth < originalWidth && newHeight > originalHeight) {
        // Vertical - Taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 27;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Vertical smaller, narrower
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 28;
      }
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Square narrower, same height
        // crop from center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 29;
      }
      if (newWidth === originalWidth && newHeight > originalHeight) {
        // Square same width, taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 30;
      }
    }
    if (outputVariant === 'square') {
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Square wider, taller
        // zoom to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 31;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Square smaller, narrower
        // resize to smaller
        mainContext.drawImage(
          imgObj,
          0,
          0,
          originalWidth,
          originalHeight,
          0,
          0,
          newWidth,
          newHeight,
        );
        variant = 32;
      }
      if (newWidth === originalWidth && newHeight === originalHeight) {
        // Square the same params
        // push original
        mainContext.drawImage(
          imgObj,
          0,
          0,
          originalWidth,
          originalHeight,
          0,
          0,
          newWidth,
          newHeight,
        );
        variant = 33;
      }
    }
  }
  if (inputVariant === 'vertical') {
    // Case vertical image
    if (outputVariant === 'horizontal') {
      if (newWidth === originalWidth && newHeight < originalHeight) {
        // Horizontal - Same Height
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 34;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Horizontal - Shorter
        // zomm to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        variant = 35;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
      }
      if (newWidth > originalWidth && newHeight < originalHeight) {
        // Horizontal - Taller
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 36;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Horizontal - wider, taller
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 37;
      }
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Horizontal - narrower, same height
        // crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 38;
      }
    }
    if (outputVariant === 'square') {
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Square - same height
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 39;
      }
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Square - taller
        // zomm to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 40;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Square - Shorter
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 41;
      }
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Square - wider, same height
        // crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 42;
      }
      if (newWidth > originalWidth && newHeight < originalHeight) {
        // Square - wider, smaller
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 43;
      }
      if (newWidth === originalWidth && newHeight < originalHeight) {
        // Square, same width, narrower
        // Crop from top
        const cropPoints = getCroppedTopPoints(
          originalWidth,
          originalHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(imgObj, x1, y1, x2, y2, 0, 0, x2, y2);
        variant = 44;
      }
    }
    if (outputVariant === 'vertical') {
      if (newWidth > originalWidth && newHeight > originalHeight) {
        // Vertical - wider, taller
        //  zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 45;
      }
      if (newWidth < originalWidth && newHeight === originalHeight) {
        // Vertical, narrower, same height
        // zoom to maxScale, crop from center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 46;
      }
      if (newWidth < originalWidth && newHeight < originalHeight) {
        // Vertical - narrower, smaller
        // zoom to maxScale, crop from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 47;
      }
      if (newWidth > originalWidth && newHeight === originalHeight) {
        // Vertical - wider, same height
        // zomm to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 48;
      }
      if (newWidth > originalWidth && newHeight < originalHeight) {
        // Vertical - wider, smaller
        // zoom to maxScale, crop 20% from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 49;
      }
      if (newWidth === originalWidth && newHeight === originalHeight) {
        // Vertical - same width, same height
        // return original
        mainContext.drawImage(imgObj, 0, 0, originalWidth, originalHeight);
        variant = 50;
      }
      if (newWidth < originalWidth && newHeight > originalHeight) {
        // Vertical - narrower, taller
        // zoom to maxScale, crop from top
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedTopPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 51;
      }
      if (newWidth === originalWidth && newHeight < originalHeight) {
        // Vertical to vertical, same width, smaller
        // crop in center
        const cropPoints = getCroppedCenterPoints(
          originalWidth,
          originalHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(imgObj, x1, y1, x2, y2, 0, 0, x2, y2);
        variant = 52;
      }
      if (newWidth === originalWidth && newHeight > originalHeight) {
        // Vertical to vertical, same width, taller
        // zomm to maxScale, crop in center
        const scaledCanvas = getScaledCanvas(
          imgObj,
          originalWidth,
          originalHeight,
          maxScale,
        );
        const canvasWidth = Math.round(originalWidth * maxScale);
        const canvasHeight = Math.round(originalHeight * maxScale);
        const cropPoints = getCroppedCenterPoints(
          canvasWidth,
          canvasHeight,
          newWidth,
          newHeight,
        );
        const {x1, x2, y1, y2} = cropPoints;
        mainContext.drawImage(
          scaledCanvas,
          x1,
          y1,
          x2 - 1,
          y2 - 1,
          0,
          0,
          x2,
          y2,
        );
        variant = 53;
      }
    }
  }
  if (variant === null) {
    // if no match, draw black image
    mainContext.drawImage(imgObj, -50, -50, 50, 50);
  }
  // info object for debug information
  // const debugInfo = {
  //   input: `input variant => ${inputVariant}`,
  //   output: `output variant => ${outputVariant}`,
  //   resizeTo: `resize to ${newWidth}x${newHeight}`,
  //   resizeFrom: `resized from ${originalWidth}x${originalHeight}`,
  //   resizingRuleNumber: `resizing rule => ${variant}`,
  //   image: mainCanvas.toDataURL('image/jpeg'),
  //   dotsCenter: getCroppedCenterPoints(
  //     originalWidth * maxScale,
  //     originalHeight * maxScale,
  //     newWidth,
  //     newHeight,
  //   ),
  //   dotsTop: getCroppedTopPoints(
  //     originalWidth * maxScale,
  //     originalHeight * maxScale,
  //     newWidth,
  //     newHeight,
  //   ),
  //   maxScale,
  // };
  // console.log(debugInfo);
  const res = mainCanvas.toDataURL(EXTENSION_TO_CONVERT);
  return res;
};

export default imageResizer;
