import React from 'react';

const Desktop = () => (
  <header className="ir-header ir-header--desktop">
    <div className="ir-header__titles">
      <h1>Free Image Resizer</h1>
      <h2 className="ir-header__subtitle">Resize images in 3 easy steps</h2>
      <div className="ir-header__steps">
        <div className="ir-header__step">
          <span className="ir-header__step-number">1</span>
          <h3 className="ir-header__step-title">Upload an image</h3>
          <span className="ir-header__step-description">
            Upload an image or paste a link to the
            <br />
            image and we will do it for you
          </span>
        </div>
        <div className="ir-header__step">
          <span className="ir-header__step-number">2</span>
          <h3 className="ir-header__step-title">Select the size</h3>
          <span className="ir-header__step-description">
            Choose as many image sizes as
            <br /> you like from the list below.
          </span>
        </div>
        <div className="ir-header__step">
          <span className="ir-header__step-number">3</span>
          <h3 className="ir-header__step-title">Download</h3>
          <span className="ir-header__step-description">
            Download your resized image or convert
            <br /> to mp4 with the Promo video editor
          </span>
        </div>
      </div>
    </div>
  </header>
);

export default Desktop;
