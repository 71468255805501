import React, { Component } from 'react';
import propTypes from 'prop-types';
import CheckeboxChecked from 'assets/checkbox-checked';
import CheckeboxNotChecked from 'assets/checkbox-not-checked';

import styles from './checkbox.module.scss';

class Checkbox extends Component {
  state = {
    checked: this.props.value,
  };

  componentWillReceiveProps({ checked }) {
    this.setState({ checked });
  }

  toggleCheck = () => {
    this.setState(
      ({ checked }) => ({ checked: !checked }),
      () => this.props.onChange(this.state.checked),
    );
  };

  render() {
    const { text, id, disabled } = this.props;
    const { checked } = this.state;
    return (
      <div
        role="checkbox"
        tabIndex={0}
        onClick={!disabled ? this.toggleCheck : () => null}
        className={!disabled ? styles.checkbox : styles.checkboxDisabled}
        aria-checked={checked}
      >
        <input
          type="checkbox"
          id={id}
          disabled={disabled}
          checked={!disabled && checked}
        />
        {!disabled && checked ? <CheckeboxChecked /> : <CheckeboxNotChecked />}
        <label
          className={
            !disabled
              ? styles.checkboxTextLabel
              : styles.checkboxDisabledTextLabel
          }
        >
          {text}
        </label>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  value: false,
};

Checkbox.propTypes = {
  text: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  value: propTypes.bool,
  onChange: propTypes.func.isRequired,
};

export default Checkbox;
