import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import classnames from 'classnames';

import { connect, actions } from 'store';
import {
  EXTENSION_TO_DOWNLOAD,
  EXTENSION_TO_CONVERT,
  MAX_IMAGE_DIMENSIONS,
  MIN_IMAGE_DIMENSIONS,
} from 'constants/index';
import withDownloading from 'hocs/withDownloading';
import trackEvent from 'utils/promoReportingWrapper';
import setImageToLocalStorage from 'utils/setImageToLocalStorage';
import {
  putObjectInDatabase,
  getAllObjectsFromDatabaseStore,
} from 'services/image-state-service';
import { isDateInFuture } from 'utils/dates';
import { handleImageDownload } from 'services/idb-image-download-service';
import InputSection from './components/InputSection';
import Dropzone from './components/Dropzone';
import Loading from './components/Loading';
import ImageEditor from './components/ImageEditor';

import styles from './mainImage.module.scss';
import './zoomSlider.css';
import './cropper.css';

const EDITOR_CONTAINER_STYLES = {
  width: 487,
  height: 289,
  overflow: 'hidden',
  background: '#000 !important',
};
const ZOOM_PARAMS = { max: 4, step: 0.01, min: 0.036 };
class MainImage extends Component {
  state = {
    zoomScale: 0,
    sliderScale: 0,
    actionAvailable: false,
    currentFlip: false,
    resizeInputs: {
      width: 0,
      height: 0,
      scaleX: 0,
      scaleY: 0,
    },
    aspectRatio: {
      lock: true,
      width: null,
      height: null,
    },
  };

  debouncedTrack = debounce(
    pagePlatform =>
      trackEvent('IMR zoom clicked', {
        pagePlatform,
        buttonSection: 'image resizer editor',
      }),
    200,
  );

  constructor(props) {
    super(props);
    this.regexNumber = /[+-]?\d+(?:\.\d+)?/g;
  }

  async componentDidMount() {
    const originalImage = await getAllObjectsFromDatabaseStore({
      name: 'originalImage',
      storeName: 'originalImage',
    });

    if (
      originalImage.length > 0 &&
      isDateInFuture(originalImage[0].data.timestamp)
    ) {
      actions.setLoadingFromDb(true);
      actions.setImageToStore(originalImage[0].data);
    } else {
      actions.resetState();
    }
  }

  setEditorRef = ref => {
    this.editorRef = ref;
  };

  downloadImage = () => {
    const { pagePlatform, originalImage, downloadImage } = this.props;
    if (this.editorRef) {
      const { width: newWidth, height: newHeight } = originalImage;

      const image = this.editorRef
        .getCroppedCanvas({ newWidth, newHeight })
        .toDataURL(EXTENSION_TO_CONVERT);

      downloadImage({
        image,
        imageName: `resized-image-Promo.${EXTENSION_TO_DOWNLOAD}`,
      });

      trackEvent('IMR image downloaded button clicked', {
        buttonSection: 'image resizer editor',
        pagePlatform,
      });

      trackEvent('IMR image downloaded successfully', {
        countOfImages: 1,
        isMulti: false,
        buttonSection: 'image resizer editor',
        pagePlatform,
      });
    }
  };

  turnIntoVideo = () => {
    const {
      token,
      originalImage: { width: newWidth, height: newHeight },
    } = this.props;
    if (this.editorRef) {
      trackEvent('IMR turn into video clicked', {
        buttonSection: 'image resizer editor',
      });
      const image = this.editorRef
        .getCroppedCanvas({ newWidth, newHeight })
        .toDataURL(EXTENSION_TO_CONVERT);
      setImageToLocalStorage(image, token);
    }
  };

  updateDbOriginalImageState = async () => {
    await putObjectInDatabase({
      name: 'state',
      data: this.state,
      storeName: 'originalImageState',
    });
  };

  updateScale = (scale, type) => {
    const { pagePlatform } = this.props;
    if (type === 'wheel') {
      let { ratio } = scale.detail;
      if (ratio > ZOOM_PARAMS.max) {
        ratio = ZOOM_PARAMS.max;
        scale.preventDefault();
      }
      if (ratio < ZOOM_PARAMS.min) {
        ratio = ZOOM_PARAMS.min;
        scale.preventDefault();
      }
      if (!this.props.isLoadingFromDb) {
        this.setState({ sliderScale: parseFloat(ratio) }, async () => {
          await this.updateDbOriginalImageState();
        });
      }
    } else if (!this.props.isLoadingFromDb) {
      this.setState(
        {
          sliderScale: parseFloat(scale),
          zoomScale: parseFloat(scale),
        },
        async () => {
          await this.updateDbOriginalImageState();
        },
      );
      this.debouncedTrack(pagePlatform);
    }
  };

  flipImage = () => {
    const { pagePlatform } = this.props;
    const { currentFlip } = this.state;
    if (!currentFlip) {
      const { scaleX } = this.editorRef.getData();
      this.editorRef.setData({ scaleX: -Math.abs(scaleX) });
    } else {
      const { scaleX } = this.editorRef.getData();
      this.editorRef.setData({ scaleX: Math.abs(scaleX) });
    }

    if (!this.props.isLoadingFromDb) {
      this.setState(
        prevState => ({ currentFlip: !prevState.currentFlip }),
        async () => {
          await this.updateDbOriginalImageState();
        },
      );

      trackEvent('IMR flip image clicked', {
        pagePlatform,
        buttonSection: 'image resizer editor',
      });
    }
  };

  checkInput = number => {
    if (number > MAX_IMAGE_DIMENSIONS) {
      this.openFailedResizeBigPopup();
      return false;
    }

    if (number < MIN_IMAGE_DIMENSIONS) {
      this.openFailedResizeSmallPopup();
      return false;
    }

    return true;
  };

  openFailedResizeSmallPopup = () => {
    trackEvent('IMR size is too small pop up', {});
    actions.openPopup({
      type: 'default',
      isOpen: true,
      title: 'This Size Is Too Small',
      description: ['Sorry, we don’t support resizing to less than 75px.'],
      buttons: {
        singleBlueButton: {
          text: 'OK',
          handler: actions.closePopup,
        },
      },
    });
  };

  openFailedResizeBigPopup = () => {
    actions.openPopup({
      type: 'default',
      isOpen: true,
      title: 'This Size Is Too Big',
      description: ['Sorry, we don’t support resizing to more than 7000px.'],
      buttons: {
        whiteButton: {
          text: 'OK',
          handler: actions.closePopup,
        },
      },
    });
  };

  checkScale = (value, scaleType) => {
    const {
      originalImage: { width, height },
    } = this.props;
    const number = this.regexNumber.exec(value)[0];

    let field = '';
    if (scaleType === 'X') {
      field = width;
    } else {
      field = height;
    }

    const calculatedWidth = (number * field) / 100;

    if (calculatedWidth > MAX_IMAGE_DIMENSIONS) {
      this.openFailedResizeBigPopup();
      return false;
    }

    if (calculatedWidth < MIN_IMAGE_DIMENSIONS) {
      this.openFailedResizeSmallPopup();
      return false;
    }

    return true;
  };

  setValues = ({
    width = this.state.resizeInputs.width,
    height = this.state.resizeInputs.height,
    scaleX = this.state.resizeInputs.scaleX,
    scaleY = this.state.resizeInputs.scaleY,
  }) => {
    if (!this.checkInput(width)) {
      return;
    }

    if (!this.checkInput(height)) {
      return;
    }

    if (!this.props.isLoadingFromDb) {
      this.editorRef.setData({ scaleX, scaleY });
      const { top, left } = this.editorRef.getCanvasData();
      const data = {
        width,
        height,
        scaleX,
        scaleY,
        top,
        left,
      };

      this.setState(
        {
          resizeInputs: data,
        },
        async () => {
          await this.updateDbOriginalImageState();
        },
      );
    }
  };

  onChangeInputWidth = ({ target: { value: width } }) => {
    trackEvent('IMR resizing using custom dimensions', {
      dimensionType: 'Width',
      value: this.state.aspectRatio.lock,
    });

    const ratio = width / this.state.resizeInputs.width;

    this.setValues({
      scaleX: this.state.resizeInputs.scaleX * ratio,
      scaleY: this.state.aspectRatio.lock
        ? this.state.resizeInputs.scaleY * ratio
        : this.state.resizeInputs.scaleY,
      width,
      height: this.state.aspectRatio.lock
        ? this.state.resizeInputs.height * ratio
        : this.state.resizeInputs.height,
    });
  };

  onChangeInputHeight = ({ target: { value: height } }) => {
    trackEvent('IMR resizing using custom dimensions', {
      dimensionType: 'Height',
      value: this.state.aspectRatio.lock,
    });

    const ratio = height / this.state.resizeInputs.height;

    this.setValues({
      scaleX: this.state.aspectRatio.lock
        ? this.state.resizeInputs.scaleX * ratio
        : this.state.resizeInputs.scaleX,
      scaleY: this.state.resizeInputs.scaleY * ratio,
      width: this.state.aspectRatio.lock
        ? this.state.resizeInputs.width * ratio
        : this.state.resizeInputs.width,
      height,
    });
  };

  onChangeInputXScale = ({ target: { value: scaleX } }) => {
    trackEvent('IMR resizing using custom dimensions', {
      dimensionType: 'X scale',
      value: this.state.aspectRatio.lock,
    });

    const ratio = scaleX / (this.state.resizeInputs.scaleX * 100);

    this.setValues({
      scaleX: scaleX / 100,
      scaleY: this.state.aspectRatio.lock
        ? this.state.resizeInputs.scaleY * ratio
        : this.state.resizeInputs.scaleY,
      width: this.state.resizeInputs.width * ratio,
      height: this.state.aspectRatio.lock
        ? this.state.resizeInputs.height * ratio
        : this.state.resizeInputs.height,
    });
  };

  onChangeInputYScale = ({ target: { value: scaleY } }) => {
    trackEvent('IMR resizing using custom dimensions', {
      dimensionType: 'Y scale',
      value: this.state.aspectRatio.lock,
    });

    const ratio = scaleY / (this.state.resizeInputs.scaleY * 100);

    this.setValues({
      scaleX: this.state.aspectRatio.lock
        ? this.state.resizeInputs.scaleX * ratio
        : this.state.resizeInputs.scaleX,
      scaleY: scaleY / 100,
      width: this.state.aspectRatio.lock
        ? this.state.resizeInputs.width * ratio
        : this.state.resizeInputs.width,
      height: this.state.resizeInputs.height * ratio,
    });
  };

  onChangeAspectRatio = nextValue => {
    if (!this.props.isLoadingFromDb) {
      const { height, width } = this.editorRef.getData();
      if (nextValue) {
        this.editorRef.setAspectRatio(width / height);
      }

      if (!nextValue) {
        this.editorRef.setAspectRatio(NaN);
      }

      this.setState(
        {
          aspectRatio: {
            lock: nextValue,
            height: nextValue && height,
            width: nextValue && width,
          },
        },
        async () => {
          await this.updateDbOriginalImageState();
        },
      );

      trackEvent('IMR lock aspect ratio check box clicked', {
        value: nextValue,
      });
    }
  };

  afterImageReady = async () => {
    if (!this.props.isLoadingFromDb) {
      this.setState({ zoomScale: 1 });

      const {
        naturalWidth: width,
        naturalHeight: height,
      } = this.editorRef.getCanvasData();

      const canvasData = this.editorRef.getCanvasData();
      const zoomRatio = (canvasData.width / canvasData.naturalWidth)
        .toString()
        .substr(0, 4);

      const left = (EDITOR_CONTAINER_STYLES.width - width) / 2;
      const top = (EDITOR_CONTAINER_STYLES.height - height) / 2;

      if (
        width < EDITOR_CONTAINER_STYLES.width &&
        height < EDITOR_CONTAINER_STYLES.height
      ) {
        this.setState({
          zoomScale: 1,
        });
      } else if (width < height && height > EDITOR_CONTAINER_STYLES.width) {
        this.editorRef.setCanvasData({
          width,
          left,
          top: -height / 2 + EDITOR_CONTAINER_STYLES.height,
        });
        const scale = EDITOR_CONTAINER_STYLES.width / width;
        this.setState({
          zoomScale: scale > ZOOM_PARAMS.max ? ZOOM_PARAMS.max : scale,
        });
      } else if (width === height && height > EDITOR_CONTAINER_STYLES.width) {
        this.editorRef.setCanvasData({
          width,
          left,
          top,
        });
        const scale = EDITOR_CONTAINER_STYLES.width / width;
        this.setState({
          zoomScale: scale > ZOOM_PARAMS.max ? ZOOM_PARAMS.max : scale,
        });
      } else if (width > height && width > EDITOR_CONTAINER_STYLES.width) {
        this.editorRef.setCanvasData({ height, left, top });
        this.setState({
          zoomScale: EDITOR_CONTAINER_STYLES.height / height,
        });
      } else {
        this.setState({
          zoomScale: parseFloat(zoomRatio),
        });
      }
      this.editorRef.clear();

      if (canvasData.top === 0 && canvasData.left === 0) {
        this.editorRef.canvas.style.transform = 'translate3d(0,0,0)';
        this.editorRef.canvas.style.background = '#000';
      }

      this.setState(
        prevState => ({
          actionAvailable: true,
          sliderScale: prevState.zoomScale,
          resizeInputs: {
            ...this.editorRef.getCropBoxData(),
            ...this.editorRef.getCanvasData(),
            scaleX: 1,
            scaleY: 1,
            height,
            width,
          },
        }),
        async () => {
          await this.updateDbOriginalImageState();
        },
      );
    } else {
      const imageState = await getAllObjectsFromDatabaseStore({
        storeName: 'originalImageState',
      });

      if (imageState.length > 0) {
        this.setState(
          {
            ...imageState[0].data,
          },
          () => {
            // measurements changes to canvas after db load
            if (imageState[0].data.resizeInputs.width) {
              this.editorRef.setCanvasData({
                width:
                  (imageState[0].data.resizeInputs.width *
                    imageState[0].data.zoomScale) /
                  imageState[0].data.resizeInputs.scaleX,
              });
            }
            if (imageState[0].data.resizeInputs.height) {
              this.editorRef.setCanvasData({
                height:
                  (imageState[0].data.resizeInputs.height *
                    imageState[0].data.zoomScale) /
                  imageState[0].data.resizeInputs.scaleY,
              });
            }

            // scale changes to canvas after db load
            if (
              imageState[0].data.resizeInputs.scaleX &&
              imageState[0].data.resizeInputs.scaleX
            ) {
              this.editorRef.setData({
                scaleX: imageState[0].data.resizeInputs.scaleX,
                scaleY: imageState[0].data.resizeInputs.scaleY,
              });
            }

            // position changes to canvas after db load
            if (imageState[0].data.resizeInputs.left) {
              this.editorRef.setCanvasData({
                left: imageState[0].data.resizeInputs.left,
              });
            }
            if (imageState[0].data.resizeInputs.top) {
              this.editorRef.setCanvasData({
                top: imageState[0].data.resizeInputs.top,
              });
            }

            // flip changes to canvas after db load
            if (imageState[0].data.currentFlip) {
              const { scaleX } = this.editorRef.getData();
              this.editorRef.setData({ scaleX: -Math.abs(scaleX) });
            } else {
              const { scaleX } = this.editorRef.getData();
              this.editorRef.setData({ scaleX: Math.abs(scaleX) });
            }

            this.editorRef.clear();
          },
        );
        actions.setLoadingFromDb(false);
      }
      await handleImageDownload(this.downloadImage);
    }
  };

  handleImageRemove = async () => {
    const { pagePlatform } = this.props;
    actions.resetState();
    trackEvent('IMR image remove validation message button clicked', {
      value: 'remove',
      pagePlatform,
    });

    this.setState({
      actionAvailable: false,
      resizeInputs: {
        height: null,
        width: null,
        scaleX: null,
        scaleY: null,
      },
      aspectRatio: {
        lock: true,
        width: null,
        height: null,
      },
    });
  };

  handleCrop = e => {
    if (!this.props.isLoadingFromDb) {
      this.setState(
        prevState => {
          const {
            resizeInputs: { height: lastHeight, width: lastWidth },
          } = prevState;
          const { top, left } = this.editorRef.getCanvasData();
          const data = {
            ...e.detail,
            height: lastHeight || e.detail.height,
            width: lastWidth || e.detail.width,
            top,
            left,
          };

          return {
            resizeInputs: data,
          };
        },
        async () => {
          await this.updateDbOriginalImageState();
        },
      );
    }
  };

  render() {
    const {
      originalImage: { source },
      originalImage,
      progress: { total, loaded },
    } = this.props;

    const {
      actionAvailable,
      aspectRatio,
      resizeInputs,
      sliderScale,
      zoomScale,
    } = this.state;

    return (
      <div
        className={classnames(
          styles.mainImage,
          total === loaded ? styles.mainImageLoaded : '',
        )}
      >
        <div className={styles.mainImageContainer}>
          {!source ? (
            <Dropzone />
          ) : (
            <>
              {total === loaded ? (
                <ImageEditor
                  handleImageRemove={this.handleImageRemove}
                  zoomParams={ZOOM_PARAMS}
                  editorContainerStyles={EDITOR_CONTAINER_STYLES}
                  sliderScale={sliderScale}
                  zoomScale={zoomScale}
                  updateScale={this.updateScale}
                  flipImage={this.flipImage}
                  afterImageReady={this.afterImageReady}
                  setEditorRef={this.setEditorRef}
                  onCrop={this.handleCrop}
                  debouncedTrack={this.debouncedTrack}
                />
              ) : (
                <Loading total={total} loaded={loaded} />
              )}
            </>
          )}
        </div>
        <InputSection
          originalImage={originalImage}
          resizeInputs={resizeInputs}
          actionAvailable={actionAvailable}
          ratioValue={aspectRatio.lock}
          onChangeInputWidth={this.onChangeInputWidth}
          onChangeInputHeight={this.onChangeInputHeight}
          onChangeInputXScale={this.onChangeInputXScale}
          onChangeInputYScale={this.onChangeInputYScale}
          onChangeAspectRatio={this.onChangeAspectRatio}
          turnIntoVideo={this.turnIntoVideo}
          downloadImage={this.downloadImage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  originalImage,
  progress,
  pagePlatform,
  originalImageDimensions,
  token,
  isLoadingFromDb,
}) => ({
  originalImage,
  progress,
  pagePlatform,
  originalImageDimensions,
  token,
  isLoadingFromDb,
});

MainImage.propTypes = {
  originalImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    source: PropTypes.string,
    xScale: PropTypes.string,
    yScale: PropTypes.string,
  }).isRequired,
  pagePlatform: PropTypes.string.isRequired,
  originalImageDimensions: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  progress: PropTypes.shape({
    total: PropTypes.number,
    loaded: PropTypes.number,
  }).isRequired,
  downloadImage: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  isLoadingFromDb: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withDownloading(MainImage));
