import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalContent from './components/ModalContent';

import styles from './index.module.scss';

class Modal extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen) document.body.classList.add('modal-open');
    else document.body.classList.remove('modal-open');
  }

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const {
      isOpen,
      title,
      buttons,
      type,
      description,
      payload: { shareSection },
    } = this.props;

    if (!isOpen) return null;

    return (
      <div className={styles.modalWrapper}>
        <ModalContent
          type={type}
          title={title}
          onClose={this.onClose}
          buttons={buttons}
          description={description}
          shareSection={shareSection}
        />
      </div>
    );
  }
}

Modal.defaultProps = {
  isOpen: false,
  payload: {},
  buttons: {},
  type: 'default',
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.any).isRequired,
  payload: PropTypes.objectOf(PropTypes.string),
  buttons: PropTypes.shape({
    whiteButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
    blueButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  }),
  type: PropTypes.string,
};

export default Modal;
