const getCroppedTopPoints = (width, height, newWidth, newHeight) => {
  const centerX = width / 2;
  const halfOfNewWidth = Math.round(newWidth / 2);
  const startPointX = Math.round(centerX - halfOfNewWidth);
  const endPointX = Math.floor(centerX + halfOfNewWidth);
  const startPointY = 0;
  const endPointY = newHeight;
  return {
    x1: Math.abs(startPointX),
    x2: Math.abs(endPointX),
    y1: Math.abs(startPointY),
    y2: Math.abs(endPointY),
  };
};

export default getCroppedTopPoints;
