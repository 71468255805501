import React from 'react';
import withWrapper from 'hocs/withWrapper';

const FlipIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.75C12.3797 2.75 12.6935 3.03215 12.7431 3.39823L12.75 3.5V21.5C12.75 21.9142 12.4142 22.25 12 22.25C11.6203 22.25 11.3065 21.9678 11.2568 21.6018L11.25 21.5V3.5C11.25 3.08579 11.5858 2.75 12 2.75ZM15.9521 6.75134L15.8982 6.75685L15.8549 6.76398L15.8006 6.77679L15.7081 6.80894L15.6215 6.8524L15.5766 6.88082L15.5417 6.90627L15.4697 6.96967L15.4298 7.01245L15.3718 7.09005L15.3245 7.17391L15.3089 7.20807L15.2768 7.30062L15.264 7.35561L15.2568 7.39823L15.2515 7.45104L15.25 17.5L15.2568 17.6018C15.3065 17.9678 15.6203 18.25 16 18.25H21L21.1021 18.2433C21.6005 18.177 21.9046 17.6321 21.6708 17.1646L16.6476 7.12146L16.6192 7.07663L16.5937 7.0417L16.5303 6.96967L16.4875 6.92983L16.4099 6.87181L16.3261 6.82456L16.2919 6.80894L16.1994 6.77679L16.1444 6.76399L16.1018 6.75685L16.049 6.75148L15.9521 6.75134ZM16.75 16.75V10.677L19.786 16.75H16.75ZM7.89822 6.75685L7.95103 6.75148L8.04793 6.75134L8.10176 6.75685L8.14509 6.76398L8.24131 6.78979L8.29192 6.80894L8.37853 6.8524L8.42337 6.88082L8.50175 6.94231L8.53032 6.96967L8.57016 7.01245L8.62818 7.09005L8.67543 7.17391L8.69105 7.20807L8.7232 7.30062L8.736 7.35561L8.74314 7.39823L8.74999 7.5V17.5C8.74999 17.8797 8.46784 18.1935 8.10176 18.2432L7.99999 18.25H2.99999C2.4773 18.25 2.12592 17.7343 2.28949 17.259L2.32917 17.1646L7.35239 7.12146L7.38081 7.07663L7.40626 7.0417L7.46966 6.96967L7.51244 6.92983L7.59005 6.87181L7.6739 6.82456L7.70806 6.80894L7.80061 6.77679L7.8556 6.76399L7.89822 6.75685ZM7.24999 10.675L4.21299 16.75H7.24999V10.675Z"
      fill="white"
    />
  </svg>
);

export default withWrapper(FlipIcon);
