import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Loader from 'assets/loader';

import styles from './index.module.scss';

const Loading = ({ total, loaded }) => (
  <Fragment>
    <div className={styles.loadingArea}>
      <div className={styles.loaderWrapper}>
        <div className={styles.loader}>
          <div
            className={styles.loading}
            style={{
              transform: `translate3d(${(loaded * 100) / total - 100}%, 0, 0)`,
            }}
          />
        </div>
        <p>Uploading image</p>
      </div>
      <div className={styles.dropzoneButtonsContainer}>
        <div disabled="disabled" className={styles.dropzoneFile}>
          Browse
          <input type="file" />
        </div>
        <button
          disabled="disabled"
          type="button"
          className={styles.dropzoneImport}
        >
          Import from URL
        </button>
      </div>
    </div>
    <button type="button" className={styles.loadingImageMobile}>
      <Loader />
      Loading...
    </button>
  </Fragment>
);

Loading.defaultProps = {
  total: 0,
  loaded: 0,
};

Loading.propTypes = {
  total: PropTypes.number,
  loaded: PropTypes.number,
};

export default Loading;
