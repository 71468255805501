import React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import './index.css';

const ReactHint = ReactHintFactory(React);

const Tooltip = () => (
  <ReactHint autoPosition events={{ hover: true }} delay={100} />
);

const TooltipClick = () => (
  <ReactHint
    persist
    attribute="data-rh-click"
    autoPosition
    delay={100}
    events={{ click: true }}
  />
);

export { Tooltip, TooltipClick };
