import React from 'react';

const Replace = () => (
  <svg width="16" height="20" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="#0E1114"
      strokeWidth="1.4"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.889 1L15 4.273l-3.111 3.272" />
      <path d="M1 9.182V7.545c0-1.807 1.393-3.272 3.111-3.272H15M4.111 19L1 15.727l3.111-3.272" />
      <path d="M15 10.818v1.637c0 1.807-1.393 3.272-3.111 3.272H1" />
    </g>
  </svg>
);

export default Replace;
