import React from 'react';

const SocialNav = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
    <path
      d="M10.4697 13.9697C10.7359 13.7034 11.1526 13.6792 11.4462 13.8971L11.5303 13.9697L16 18.439L20.4697 13.9697C20.7359 13.7034 21.1526 13.6792 21.4462 13.8971L21.5303 13.9697C21.7966 14.2359 21.8208 14.6526 21.6029 14.9462L21.5303 15.0303L16.5303 20.0303C16.2641 20.2966 15.8474 20.3208 15.5538 20.1029L15.4697 20.0303L10.4697 15.0303C10.1768 14.7374 10.1768 14.2626 10.4697 13.9697Z"
      fill="#0E1114"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#BFC4CB" />
  </svg>
);

export default SocialNav;
