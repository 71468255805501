import { useEffect, useState } from 'react';
import { userService } from 'services/user-session-service';

const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const user = await userService.getUser();
      setAccountInfo(user);
    })();
  }, []);

  return accountInfo;
};

export default useAccountInfo;
