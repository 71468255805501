import React, { Fragment, PureComponent } from 'react';
import classnames from 'classnames';
import { Element } from 'react-scroll';
import trackEvent from 'utils/promoReportingWrapper';
import withDownloading from 'hocs/withDownloading';
import { PLATFORM_ICONS, PLACEHOLDERS } from 'constants/texts/texts';
import { IMAGE_EDITOR_MOUNTING_DELAY, EDITED_IMAGE_ID } from 'constants/index';
import { connect, actions } from 'store';
import { ArrowUp, ArrowDown } from 'assets/icons/arrow';
import {
  getAllObjectsFromDatabaseStore,
  putObjectInDatabase,
} from 'services/image-state-service';

import MainImage from './components/MainImage';
import ImageEditor from './components/ImageEditor';
import Header from './components/Header';

import styles from './index.module.scss';
import './read-more.scss';

const THUMBNAILS_IN_A_ROW = 3;
const MOBILE_VISIBLE_THUMBNAILS_COUNT = 99;
const INITIAL_STATE = {
  selectedSections: [],
  openSections: [],
  editedImage: null,
  mobileAllVisible: ['Facebook'],
  contentWrapperWidth: '',
  imageEditorClassName: '',
};

class SocialSections extends PureComponent {
  state = {
    ...INITIAL_STATE,
  };

  constructor(props) {
    super(props);
    this.imagesWrapperRef = [];
  }

  async componentDidMount() {
    this.setState({
      contentWrapperWidth: this.imagesWrapperRef[0].clientWidth,
    });
    const stateToRestore = await getAllObjectsFromDatabaseStore({
      storeName: 'thumbnails',
    });

    if (stateToRestore.length > 0) {
      actions.setAllThumbnailsState(stateToRestore[0].data.thumbnails);
    }

    window.addEventListener(
      'socialImageDownloadInit',
      e => {
        const { formatName, socialName } = e.detail;
        if (this.props.thumbnails) {
          const imageSocialObj = this.props.thumbnails[socialName].find(
            el => el.formatName === formatName,
          );

          if (imageSocialObj) {
            this.downloadImage(imageSocialObj, socialName);
          }
        }
      },
      false,
    );
  }

  async componentWillReceiveProps(props) {
    const {
      thumbnails,
      originalImage: { source },
      progress,
      pagePlatform,
      isLoadingFromDb,
    } = props;

    this.setImagesToCorrectSelectState(thumbnails);

    if (!source) this.resetState();

    if (
      progress.total === progress.loaded &&
      pagePlatform === 'mobile' &&
      this.props.progress.loaded !== progress.loaded
    ) {
      this.scrollToSectionAfterUpload();
    }

    if (progress.total === progress.loaded && !isLoadingFromDb) {
      await putObjectInDatabase({
        data: { thumbnails },
        name: 'thumbnails',
        storeName: 'thumbnails',
      });
    }
  }

  scrollToSectionAfterUpload = () => {
    window.scrollTo({
      top: this.sectionsContainer.offsetTop + 20,
      behavior: 'smooth',
    });
  };

  resetState = () => {
    this.setState({
      ...INITIAL_STATE,
      contentWrapperWidth: this.imagesWrapperRef[0].clientWidth,
    });
  };

  setImagesToCorrectSelectState = thumbnails => {
    const { selectedSections } = this.state;

    Object.keys(thumbnails).forEach(socialName => {
      const imagesLength = thumbnails[socialName].length;
      const selectedCount = thumbnails[socialName].reduce(
        (currValue, social) => (social.selected ? (currValue += 1) : currValue),
        0,
      );

      if (
        selectedCount === imagesLength &&
        !selectedSections.includes(socialName)
      ) {
        this.selectAllImages(socialName);
      } else if (
        selectedCount !== imagesLength &&
        selectedSections.includes(socialName)
      ) {
        this.setState(prevState => ({
          selectedSections: prevState.selectedSections.filter(
            item => item !== socialName,
          ),
        }));
      }
    });
  };

  selectAllImages = socialName => {
    const { selectedSections } = this.state;
    const isSectionSelected = selectedSections.includes(socialName);

    if (isSectionSelected) {
      this.deselectSection(socialName);
    } else {
      this.selectSection(socialName);
    }
  };

  toggleSection = socialNameAndId => {
    if (this.hasSection(socialNameAndId)) {
      const newOpenSections = new Set(this.state.openSections);
      newOpenSections.delete(socialNameAndId);
      this.setState({ openSections: [...Array.from(newOpenSections)] });
    } else {
      this.setState({
        openSections: [...this.state.openSections, socialNameAndId],
      });
    }
  };

  hasSection = socialNameAndId =>
    this.state.openSections.includes(socialNameAndId);

  selectSection = socialName => {
    const { pagePlatform } = this.props;

    trackEvent('IMR select images check box clicked', {
      categoryType: socialName,
      isMulti: true,
      value: true,
      pagePlatform,
    });
    actions.setAllSelected({ socialName, isAllSelected: true });

    this.setState(prevState => ({
      selectedSections: [...prevState.selectedSections, socialName],
    }));
  };

  deselectSection = socialName => {
    const { pagePlatform } = this.props;

    actions.setAllSelected({ socialName, isAllSelected: false });
    trackEvent('IMR select images check box clicked', {
      categoryType: socialName,
      isMulti: true,
      value: false,
      pagePlatform,
    });

    this.setState(prevState => ({
      selectedSections: prevState.selectedSections.filter(
        item => item !== socialName,
      ),
    }));
  };

  onEditClicked = (socialName, formatName, formatSize, index, id, raw) => {
    const { pagePlatform } = this.props;
    const { editedImage } = this.state;

    trackEvent('IMR edit image clicked', {
      categoryType: socialName,
      pagePlatform,
    });

    if (editedImage) {
      this.setState({ editedImage: null, imageEditorClassName: '' });
    }

    this.setState({
      editedImage: {
        socialName,
        formatName,
        formatSize,
        index,
        id,
        raw,
      },
    });
  };

  onEditClose = () => {
    const { pagePlatform } = this.props;
    this.setState({
      editedImage: null,
    });

    trackEvent('IMR editing widget closed', {
      pagePlatform,
    });
  };

  downloadImage = (socialObj, platform) => {
    const { pagePlatform, downloadImage } = this.props;
    const { formatName, source } = socialObj;

    trackEvent('IMR image downloaded button clicked', {
      buttonSection: 'thumbnail',
      pagePlatform,
    });

    downloadImage({ image: source, socialName: platform, formatName });

    trackEvent('IMR image downloaded successfully', {
      countOfImages: 1,
      isMulti: false,
      buttonSection: 'thumbnail',
      categoryTypeArray: [platform],
      imageNameArray: [`${platform} ${formatName}`],
      pagePlatform,
    });
  };

  getClassnameByPosition = () => {
    setTimeout(() => {
      const editedImage = this.imagesWrapperRef.find(
        value => value.id === EDITED_IMAGE_ID && value,
      );

      if (!editedImage) return false;

      const editedImageRect = editedImage.getBoundingClientRect();

      const firstImageLeftOffset = this.imagesWrapperRef[0].getBoundingClientRect()
        .left;

      const imagesContainerWidth =
        document.body.clientWidth - firstImageLeftOffset * 2;

      const thirdPartOfImagesContainer =
        imagesContainerWidth / THUMBNAILS_IN_A_ROW;

      const editedImageTotalLeft =
        editedImageRect.left + editedImageRect.width / 2;

      if (
        editedImageTotalLeft <
        thirdPartOfImagesContainer + firstImageLeftOffset
      ) {
        this.setState({ imageEditorClassName: 'left' });
      } else if (
        editedImageTotalLeft <
        thirdPartOfImagesContainer * 2 + firstImageLeftOffset
      ) {
        this.setState({ imageEditorClassName: 'center' });
      } else {
        this.setState({ imageEditorClassName: 'right' });
      }
    }, IMAGE_EDITOR_MOUNTING_DELAY);
  };

  getImageEditorDisplayProp = (i, socialName) => {
    const { editedImage } = this.state;
    const { thumbnails } = this.props;

    if (
      (editedImage.index + 1 === i + 1 &&
        editedImage.index + 1 !== thumbnails[socialName].length - 1) ||
      ((i + 1) % 3 === 0 &&
        (editedImage.index + 2 === i || editedImage.index + 1 === i)) ||
      (thumbnails[socialName].length === i + 1 &&
        editedImage.index + 1 === thumbnails[socialName].length - 1)
    ) {
      return true;
    }

    return false;
  };

  renderFigcaption = (socialObj, socialName) => {
    const socialNameAndId = `${socialName}${socialObj.id}`;
    const hasName = this.hasSection(socialNameAndId);
    return (
      <>
        <div
          className={classnames('socialCaptionHeader', {
            socialCaptionHeaderSelected: this.hasSection(socialNameAndId),
          })}
        >
          <span className={classnames('socialCaptionHeaderName')}>
            {socialObj.formatName}
          </span>
          <span
            onClick={() => this.toggleSection(socialNameAndId)}
            className={classnames('socialReadMore', 'socialReadMoreActive')}
          >
            {hasName ? 'Close' : 'Read More'}
            {hasName ? <ArrowDown /> : <ArrowUp />}
          </span>
        </div>
        <div className={classnames('socialReadMoreText')}>
          {socialObj.formatDescription}
        </div>
      </>
    );
  };

  render() {
    const {
      originalImage,
      thumbnails,
      pagePlatform,
      originalImageDimensions,
      progress,
    } = this.props;
    const {
      selectedSections,
      editedImage,
      contentWrapperWidth,
      imageEditorClassName,
    } = this.state;

    let thumbsCounter = 0;

    return (
      <div className={styles.outerSectionsContainer}>
        <div
          ref={ref => (this.sectionsContainer = ref)}
          className={
            !originalImage.source
              ? classnames(styles.sectionsContainer, styles.disabled)
              : styles.sectionsContainer
          }
        >
          {Object.keys(thumbnails).map((socialName, index) => {
            const Icon = PLATFORM_ICONS[socialName];
            if (index === 0) thumbsCounter = 0;

            return (
              <Element
                key={socialName}
                name={socialName}
                className={styles.imageSection}
                style={{
                  border: selectedSections.includes(socialName)
                    ? `1px solid #3357FF`
                    : '',
                }}
              >
                <Header
                  className={
                    progress.total !== progress.loaded ? 'disabled' : ''
                  }
                  socialName={socialName}
                  selectedSections={selectedSections}
                  selectAllImages={this.selectAllImages}
                >
                  <Icon />
                </Header>
                <div className={styles.socialSection}>
                  {[1, 2, 3].map(col => {
                    const column = thumbnails[socialName].filter(
                      t => t.formatColumn === col,
                    );
                    return (
                      <div
                        key={`column${socialName}${col}`}
                        className={styles.socialColumn}
                      >
                        <>
                          {column.map((socialObj, i) => (
                            <div
                              key={`block_${socialObj.id}`}
                              className={styles.socialSectionBlock}
                            >
                              <Fragment>
                                {editedImage &&
                                  editedImage.socialName === socialName &&
                                  editedImage.id === socialObj.id && (
                                    <ImageEditor
                                      className={imageEditorClassName}
                                      socialName={editedImage.socialName}
                                      formatName={editedImage.formatName}
                                      formatSize={editedImage.formatSize}
                                      raw={editedImage.raw}
                                      onClose={this.onEditClose}
                                      originalWidth={originalImage.width}
                                      originalHeight={originalImage.height}
                                      id={editedImage.id}
                                    />
                                  )}
                                <div
                                  className={classnames(styles.contentWrapper, {
                                    [styles.hackImageHeight]:
                                      socialName === 'YouTube' &&
                                      pagePlatform === 'desktop',
                                    [styles.contentBottom]:
                                      pagePlatform === 'mobile' &&
                                      originalImage.source &&
                                      thumbnails[socialName].length <
                                        MOBILE_VISIBLE_THUMBNAILS_COUNT,
                                  })}
                                >
                                  <div className={styles.content}>
                                    <figure>
                                      <figcaption>
                                        {this.renderFigcaption(
                                          socialObj,
                                          socialName,
                                        )}
                                      </figcaption>
                                      <MainImage
                                        editedImage={editedImage}
                                        socialObj={socialObj}
                                        originalImage={originalImage}
                                        contentWrapperWidth={
                                          contentWrapperWidth
                                        }
                                        index={index}
                                        i={i}
                                        onEditClicked={this.onEditClicked}
                                        downloadImage={this.downloadImage}
                                        socialName={socialName}
                                        originalImageDimensions={
                                          originalImageDimensions
                                        }
                                        imageRef={imageWrapper => {
                                          if (imageWrapper) {
                                            this.imagesWrapperRef[
                                              thumbsCounter
                                            ] = imageWrapper;
                                            thumbsCounter += 1;
                                          }
                                        }}
                                        onEditedImageUpdate={
                                          this.getClassnameByPosition
                                        }
                                      />
                                    </figure>
                                  </div>
                                </div>
                              </Fragment>
                            </div>
                          ))}
                          {PLACEHOLDERS[socialName][col - 1] && (
                            <div
                              key={`placeholder${socialName}${col}`}
                              className={styles.socialSelectionPlaceholder}
                            >
                              <div />
                            </div>
                          )}
                        </>
                      </div>
                    );
                  })}
                </div>
              </Element>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  originalImage,
  thumbnails,
  pagePlatform,
  originalImageDimensions,
  progress,
  isLoadingFromDb,
}) => ({
  originalImage,
  thumbnails,
  pagePlatform,
  originalImageDimensions,
  progress,
  isLoadingFromDb,
});

export default connect(mapStateToProps)(withDownloading(SocialSections));
