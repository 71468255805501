import React, { Component } from 'react';
import Sticky from 'components/Header/sticky';
import Desktop from 'components/Header/desktop';
import Mobile from 'components/Header/mobile';

import './index.scss';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isMobile: false,
      featureFlags: {},
    };
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);

    (async () => {
      const response = await fetch('/promoVideos/user/get-feature-flags');
      const { success, featureFlags } = await response.json();

      this.setState({
        featureFlags: success ? featureFlags : {},
      });
    })();
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (window.innerWidth > 1023) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  };

  setStep = step => () => this.setState({ step });

  swipeRight = () => {
    if (this.state.step > 1) {
      this.setState({ step: this.state.step - 1 });
    }
  };

  swipeLeft = () => {
    if (this.state.step < 3) {
      this.setState({ step: this.state.step + 1 });
    }
  };

  render() {
    const { featureFlags, step } = this.state;
    const { accountInfo } = this.props;

    return (
      <>
        <Sticky accountInfo={accountInfo} featureFlags={featureFlags} />
        {this.state.isMobile ? (
          <Mobile
            step={step}
            setStep={this.setStep}
            swipeLeft={this.swipeLeft}
            swipeRight={this.swipeRight}
          />
        ) : (
          <Desktop />
        )}
      </>
    );
  }
}
