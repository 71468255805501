import React from 'react';

const DownloadIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 11.4174C15.0464 11.4174 15.3602 11.6995 15.4098 12.0656L15.4167 12.1674V13.834C15.4167 15.1153 14.4195 16.1637 13.1589 16.2456L13 16.2507H3.00001C1.71871 16.2507 0.670306 15.2535 0.588484 13.9929L0.583344 13.834V12.1674C0.583344 11.7531 0.91913 11.4174 1.33334 11.4174C1.71304 11.4174 2.02683 11.6995 2.0765 12.0656L2.08334 12.1674V13.834C2.08334 14.3041 2.43721 14.6916 2.89311 14.7445L3.00001 14.7507H13C13.4701 14.7507 13.8576 14.3968 13.9105 13.9409L13.9167 13.834V12.1674C13.9167 11.7531 14.2525 11.4174 14.6667 11.4174Z"
      fill="#3357FF"
    />
    <path
      d="M3.30301 6.63703C3.56928 6.37076 3.98594 6.34656 4.27956 6.56441L4.36367 6.63703L8.00001 10.2737L11.6363 6.63703C11.9026 6.37076 12.3193 6.34656 12.6129 6.56441L12.697 6.63703C12.9633 6.90329 12.9875 7.31996 12.7696 7.61357L12.697 7.69769L8.53034 11.8644C8.26407 12.1306 7.84741 12.1548 7.5538 11.937L7.46968 11.8644L3.30301 7.69769C3.01012 7.4048 3.01012 6.92992 3.30301 6.63703Z"
      fill="#3357FF"
    />
    <path
      d="M8 0.583984C8.3797 0.583984 8.69349 0.866138 8.74315 1.23221L8.75 1.33398V11.334C8.75 11.7482 8.41421 12.084 8 12.084C7.6203 12.084 7.30651 11.8018 7.25685 11.4358L7.25 11.334V1.33398C7.25 0.919771 7.58579 0.583984 8 0.583984Z"
      fill="#3357FF"
    />
  </svg>
);

export default DownloadIcon;
