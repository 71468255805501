import React from 'react';

const Checked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#3357FF" />
    <path
      d="M17.8159 7.26035C18.163 6.91322 18.7259 6.91322 19.073 7.26035C19.3934 7.58078 19.4181 8.08499 19.1469 8.43369L19.073 8.51743L10.8508 16.7397C10.5303 17.0601 10.0261 17.0847 9.67743 16.8136L9.59369 16.7397L4.92702 12.073C4.57989 11.7259 4.57989 11.163 4.92702 10.8159C5.24745 10.4955 5.75166 10.4708 6.10036 10.742L6.1841 10.8159L10.2222 14.8533L17.8159 7.26035Z"
      fill="white"
    />
  </svg>
);

export default Checked;
