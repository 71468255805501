import React from 'react';

const Facebook = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Image-Uploaded-Full-size"
        transform="translate(-734.000000, -10512.000000)"
      >
        <g id="socials" transform="translate(734.000000, 10512.000000)">
          <g id="facebook">
            <circle
              id="Oval"
              fill="#FFFFFF"
              fillRule="nonzero"
              cx="20"
              cy="20"
              r="20"
            />
            <path
              d="M15.948586,17.0263415 L17.7842915,17.0263415 L17.7842915,15.262439 C17.7842915,14.4839024 17.804025,13.2839024 18.3753088,12.5404878 C18.9776728,11.7536585 19.8040116,11.2190244 21.2258077,11.2190244 C23.5420231,11.2190244 24.5173496,11.5453659 24.5173496,11.5453659 L24.0580532,14.2356098 C24.0580532,14.2356098 23.2933815,14.0160976 22.5795234,14.0160976 C21.865172,14.0160976 21.2258077,14.2692683 21.2258077,14.975122 L21.2258077,17.0263415 L24.1537605,17.0263415 L23.9495192,19.6536585 L21.2258077,19.6536585 L21.2258077,28.78 L17.7842915,28.78 L17.7842915,19.6536585 L15.948586,19.6536585 L15.948586,17.0263415"
              id="Fill-3"
              fill="#6895F6"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Facebook;
