import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import loadImage from 'blueimp-load-image';

import { connect, actions } from 'store';

import trackEvent from 'utils/promoReportingWrapper';
import getImagePosition from 'utils/getImagePosition';
import imageToJpg from 'utils/imageToJpg';
import {
  MAX_INPUT_FILE_SIZE,
  SUPPORTED_MIME_TYPES,
  MAX_IMAGE_DIMENSIONS,
  MIN_IMAGE_DIMENSIONS,
  IMPORT_FROM_URL_MISTAKE,
} from 'constants/index';
import DropZoneImage from 'assets/icons/DropZoneImage';
import Upload from 'assets/icons/Upload';
import Modal from './components/Modal';

import styles from './dropzone.module.scss';

class Dropzone extends Component {
  state = {
    isUrlPopupOpen: false,
    urlInput: '',
    error: '',
  };

  handleUrlChange = event => {
    this.setState({ urlInput: event.target.value });
  };

  handleOpenUrlPopup = () => {
    this.setState({ isUrlPopupOpen: true });
  };

  handleCloseUrlPopup = () => {
    this.setState({ isUrlPopupOpen: false, urlInput: '', error: '' });
  };

  checkImageDimensions = (file, callback) => {
    const img = new Image();
    img.onload = function() {
      if (
        this.width > MAX_IMAGE_DIMENSIONS ||
        this.height > MAX_IMAGE_DIMENSIONS
      ) {
        callback(false, 'big');
      } else if (
        this.width < MIN_IMAGE_DIMENSIONS ||
        this.height < MIN_IMAGE_DIMENSIONS
      ) {
        callback(false, 'small');
      } else {
        callback(true);
      }
    };
    const src = URL.createObjectURL(file);
    img.src = src;
  };

  imageToBase64ToGlobal = file => {
    loadImage(
      file,
      img => {
        if (img.type === 'error') {
          actions.openPopup({
            type: 'default',
            isOpen: true,
            title: 'We Apologize',
            description: [
              'Oops, sorry for this general error. Stay calm and try again later.',
            ],
            buttons: {
              singleBlueButton: {
                text: 'OK',
                handler: actions.closePopup,
              },
            },
          });
        } else {
          const position = getImagePosition(img.width, img.height);
          actions.setOriginalImagePosition(position);
          const imageSrc = imageToJpg(img, img.width, img.height);

          actions.setImageToStore({
            width: `${img.width}px`,
            height: `${img.height}px`,
            source: imageSrc,
            position,
          });
        }
      },
      {
        orientation: true,
      },
    );
  };

  handleInput = async (files, method) => {
    const { pagePlatform } = this.props;
    const file = files[0];

    if (Array.isArray(method)) {
      method = 'drag and drop';
    }
    if (file && !SUPPORTED_MIME_TYPES.includes(file.type)) {
      trackEvent('IMR image uploaded failed', {
        uploadMethod: method,
        failReason: 'format not supported',
        pagePlatform,
      });

      actions.openPopup({
        type: 'default',
        isOpen: true,
        title: 'Uploaded format not supported',
        description: ['File type is not supported. We support JPG and PNG.'],
        buttons: {
          singleBlueButton: {
            text: 'OK',
            handler: actions.closePopup,
          },
        },
      });

      return false;
    }
    if (file && file.size > MAX_INPUT_FILE_SIZE) {
      trackEvent('IMR image uploaded failed', {
        uploadMethod: method,
        failReason: 'image size',
        pagePlatform,
      });

      actions.openPopup({
        type: 'default',
        isOpen: true,
        title: 'Uploaded image is too big',
        description: ['File size is too big, max size is 20MB.'],
        buttons: {
          whiteButton: {
            text: 'OK',
            handler: actions.closePopup,
          },
        },
      });

      return false;
    }

    const promise = new Promise(resolve => {
      if (file) {
        this.checkImageDimensions(file, (isAllowed, size) => {
          if (!isAllowed && size === 'big') {
            trackEvent('IMR image uploaded failed', {
              uploadMethod: method,
              failReason: 'format not supported',
              pagePlatform,
            });

            actions.openPopup({
              type: 'default',
              isOpen: true,
              title: 'Uploaded Size Is Too Big',
              description: [
                'Your image should not exceed 7000 pixels in height and width.',
              ],
              buttons: {
                singleBlueButton: {
                  text: 'OK',
                  handler: actions.closePopup,
                },
              },
            });

            resolve(false);
            return false;
          }
          if (!isAllowed && size === 'small') {
            trackEvent('IMR image uploaded failed', {
              uploadMethod: method,
              failReason: 'format not supported',
              pagePlatform,
            });

            actions.openPopup({
              type: 'default',
              isOpen: true,
              title: 'Uploaded Size Is Too Small',
              description: [
                'Your image needs to be at least 75px in height and length.',
              ],
              buttons: {
                singleBlueButton: {
                  text: 'OK',
                  handler: actions.closePopup,
                },
              },
            });

            resolve(false);
            return false;
          }
          this.imageToBase64ToGlobal(file);
          trackEvent('IMR image uploaded successfully', {
            uploadMethod: method,
            pagePlatform,
          });
        });
      }
    });

    const isAllowedImage = await promise;

    return isAllowedImage;
  };

  setImageToStore = ({ dataUrl, width, height }) => {
    const image = new Image(width, height);
    image.src = dataUrl;
    image.onload = () => {
      const imageSrc = imageToJpg(image, width, height);
      actions.setImageToStore({
        width: `${width}px`,
        height: `${height}px`,
        source: imageSrc,
      });
    };
  };

  onSubmitUrl = () => {
    const { urlInput } = this.state;
    const { pagePlatform } = this.props;

    fetch(`https://ak02-cdn.slidely.com/img-base64?url=${urlInput}`)
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          return Promise.reject(data.message);
        }
        if (
          data.width < MIN_IMAGE_DIMENSIONS ||
          data.height < MIN_IMAGE_DIMENSIONS
        ) {
          return Promise.reject(IMPORT_FROM_URL_MISTAKE);
        }
        trackEvent('IMR image uploaded successfully', {
          uploadMethod: 'import from URL',
          pagePlatform,
        });

        this.handleCloseUrlPopup();
        this.setImageToStore(data);
      })
      .catch(error => {
        if (error && typeof error !== 'object' && error !== null) {
          this.setState({
            error,
          });
          trackEvent('IMR image uploaded failed', {
            uploadMethod: 'import from URL',
            failReason: error,
            pagePlatform,
          });
        } else {
          this.setState({
            error: IMPORT_FROM_URL_MISTAKE,
          });
          trackEvent('IMR image uploaded failed', {
            uploadMethod: 'import from URL',
            failReason: IMPORT_FROM_URL_MISTAKE,
            pagePlatform,
          });
        }
      });
  };

  render() {
    const { isUrlPopupOpen, urlInput, error } = this.state;
    return (
      <Fragment>
        <ReactDropzone
          className={styles.dropzone}
          acceptClassName={styles.dropzoneAccept}
          rejectClassName={styles.dropzoneNotAccept}
          onDrop={this.handleInput}
          disableClick
        >
          <div className={styles.dropzoneImage}>
            <DropZoneImage />
            <p className={styles.dropzoneText}>Drag and drop image</p>
          </div>
          {isUrlPopupOpen ? (
            <Modal
              error={error}
              urlInput={urlInput}
              onChangeUrl={this.handleUrlChange}
              onSubmitUrl={this.onSubmitUrl}
              onClose={this.handleCloseUrlPopup}
            />
          ) : null}
          <div className={styles.dropzoneButtonsContainer}>
            <div className={styles.dropzoneFile}>
              Browse
              <input
                type="file"
                title=" "
                accept="image/jpeg,image/png,image/jpg"
                onChange={e => this.handleInput(e.target.files, 'browse')}
              />
            </div>
            <button type="button" className={styles.dropzoneFileMobile}>
              <Upload />
              Upload Image
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg"
                className={styles.dropzoneFileMobileInput}
                onChange={e => this.handleInput(e.target.files, 'browse')}
              />
            </button>
            <button
              onClick={this.handleOpenUrlPopup}
              type="button"
              className={styles.dropzoneImport}
            >
              Import from URL
            </button>
          </div>
        </ReactDropzone>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ originalImage, pagePlatform }) => ({
  originalImage,
  pagePlatform,
});

Dropzone.propTypes = {
  originalImage: propTypes.shape({
    width: propTypes.string,
    height: propTypes.string,
    source: propTypes.string,
    xScale: propTypes.string,
    yScale: propTypes.string,
  }).isRequired,
  pagePlatform: propTypes.string.isRequired,
};

export default connect(mapStateToProps)(Dropzone);
