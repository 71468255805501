import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';

function Typography(props) {
  const { className: classNameProp, variant: variantProp, ...other } = props;

  const className = classNames(styles[variantProp], classNameProp);

  const Component = variantProp === 'subtitle' ? 'p' : variantProp;

  return <Component className={className} {...other} />;
}

Typography.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'subtitle']),
};

Typography.defaultProps = {
  variant: 'h1',
  className: '',
};

export default Typography;
