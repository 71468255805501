import React from 'react';

const Linkedin = () => (
  <svg
    width="41px"
    height="40px"
    viewBox="0 0 41 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="iPhone-8-Pop-Up-After-Download-"
        transform="translate(-196.000000, -371.000000)"
      >
        <g id="Group-3" transform="translate(20.000000, 96.000000)">
          <g id="Oval-Copy-+-Oval-Mask">
            <g id="socials" transform="translate(62.000000, 275.000000)">
              <g id="linkedin" transform="translate(114.000000, 0.000000)">
                <path
                  d="M20.0369555,0.975609756 C9.54242987,0.975609756 1.0048002,9.5097561 1.0048002,20 C1.0048002,30.4897561 9.54242987,39.0243902 20.0369555,39.0243902 C30.5314811,39.0243902 39.0691108,30.4897561 39.0691108,20 C39.0691108,9.5097561 30.5314811,0.975609756 20.0369555,0.975609756 L20.0369555,0.975609756 Z M20.0369555,40 C9.00464948,40 0.0287922349,31.0278049 0.0287922349,20 C0.0287922349,8.97170732 9.00464948,0 20.0369555,0 C31.0697495,0 40.0451188,8.97170732 40.0451188,20 C40.0451188,31.0278049 31.0697495,40 20.0369555,40 L20.0369555,40 Z"
                  id="Fill-31"
                  fill="#FFFFFF"
                />
                <circle
                  id="Oval"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="20"
                  cy="20"
                  r="20"
                />
                <g
                  transform="translate(12.000000, 12.000000)"
                  fill="#6A93F5"
                  fillRule="nonzero"
                  id="Shape"
                >
                  <path d="M14.5526702,0 L1.44732984,0 C0.648,0 0,0.648 0,1.44732984 L0,14.5526283 C0,15.352 0.648,16 1.44732984,16 L14.5526283,16 C15.352,16 16,15.352 16,14.5526283 L16,1.44732984 C16,0.648 15.352,0 14.5526702,0 Z M4.7653089,13.8154555 C4.7653089,14.0480838 4.57674346,14.2366492 4.34411518,14.2366492 L2.55119372,14.2366492 C2.31856545,14.2366492 2.13,14.0480838 2.13,13.8154555 L2.13,6.29960209 C2.13,6.06697382 2.31856545,5.87840838 2.55119372,5.87840838 L4.34411518,5.87840838 C4.57674346,5.87840838 4.7653089,6.06697382 4.7653089,6.29960209 L4.7653089,13.8154555 Z M3.44328796,5.1699267 C2.50259686,5.1699267 1.74,4.40732984 1.74,3.46663874 C1.74,2.52594764 2.50259686,1.76335079 3.44328796,1.76335079 C4.38397906,1.76335079 5.14657592,2.52594764 5.14657592,3.46663874 C5.14657592,4.40732984 4.38402094,5.1699267 3.44328796,5.1699267 Z M14.1326597,13.8493822 C14.1326597,14.0632461 13.9592565,14.2366492 13.7453927,14.2366492 L11.8214555,14.2366492 C11.6075916,14.2366492 11.4341885,14.0632461 11.4341885,13.8493822 L11.4341885,10.3240209 C11.4341885,9.79811518 11.5884503,8.01947644 10.059822,8.01947644 C8.87410471,8.01947644 8.63360209,9.23690052 8.5853089,9.78324607 L8.5853089,13.8493822 C8.5853089,14.0632461 8.41194764,14.2366492 8.19804188,14.2366492 L6.33726702,14.2366492 C6.12340314,14.2366492 5.95,14.0632461 5.95,13.8493822 L5.95,6.26567539 C5.95,6.05181152 6.12340314,5.87840838 6.33726702,5.87840838 L8.19804188,5.87840838 C8.41190576,5.87840838 8.5853089,6.05181152 8.5853089,6.26567539 L8.5853089,6.9213822 C9.02497382,6.26157068 9.67837696,5.75229319 11.0695812,5.75229319 C14.1502932,5.75229319 14.1326597,8.63045026 14.1326597,10.2118534 L14.1326597,13.8493822 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Linkedin;
