import React, { useCallback } from 'react';
import { useUserLimits } from '@easyhi/promo-composite-components/lib/components/user-limits';
import { useDeferredVideoModalsPromise } from '@easyhi/promo-composite-components/lib/components/video-modals';
import { getUserLimits } from '@easyhi/promo-composite-components/lib/services/user-limits-service';
import EmbedPricingPage from '@easyhi/ts-pricing-portal/lib/components/embed-pricing-page';
import useEmbedPricing from '../../hooks/use-embed-pricing';
import useEmbedPricingParams from '../../hooks/use-embed-pricing-params';

import '@easyhi/ts-pricing-portal/lib/components/embed-pricing-page/index.css';

const EmbedPricing = ({ accountInfo, onClose }) => {
  const [, setUserLimits] = useUserLimits();
  const [showEmbedPricing, setShowEmbedPricing] = useEmbedPricing();
  const [embedPricingParams, setEmbedPricingParams] = useEmbedPricingParams();
  const { deferRef } = useDeferredVideoModalsPromise() || {};

  const pageType = 'photo-resizer';

  const onSubscriptionChange = useCallback(
    async () => {
      const userLimits = await getUserLimits();
      setUserLimits(userLimits);
      if (deferRef) {
        deferRef.resolve(true);
      }
    },
    [setUserLimits, deferRef],
  );

  const onCloseEmbedPricingPage = useCallback(
    isByUser => {
      setShowEmbedPricing(false);
      setEmbedPricingParams(undefined);
      if (isByUser && deferRef) {
        deferRef.resolve(false);
      }
      if (onClose) {
        onClose();
      }
    },
    [setShowEmbedPricing, setEmbedPricingParams, onClose, deferRef],
  );

  if (!accountInfo) {
    return null;
  }

  const { successUrl, widgetName } = embedPricingParams || {};

  return (
    <EmbedPricingPage
      isVisible={showEmbedPricing}
      onClose={onCloseEmbedPricingPage}
      onSubscriptionChange={onSubscriptionChange}
      successUrl={successUrl}
      pageType={pageType}
      widgetName={widgetName}
    />
  );
};

export default EmbedPricing;
