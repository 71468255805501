import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import styles from './index.module.scss';

const Description = ({ formatName }) => (
  <Fragment>
    <div className={styles.mobileHeader}>
      <h2 className={styles.mobileHeading}>{`Resize ${formatName}`}</h2>
    </div>
  </Fragment>
);

Description.propTypes = {
  formatName: propTypes.string.isRequired,
  formatSize: propTypes.shape({
    formatSize: propTypes.shape({
      width: propTypes.number,
      height: propTypes.number,
    }),
  }).isRequired,
};

export default Description;
