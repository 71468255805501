import React from 'react';

const Upload = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 11.4174C15.0464 11.4174 15.3602 11.6995 15.4099 12.0656L15.4167 12.1674V13.834C15.4167 15.1153 14.4196 16.1637 13.1589 16.2456L13 16.2507H3.00004C1.71874 16.2507 0.670337 15.2535 0.588514 13.9929L0.583374 13.834V12.1674C0.583374 11.7531 0.91916 11.4174 1.33337 11.4174C1.71307 11.4174 2.02686 11.6995 2.07653 12.0656L2.08337 12.1674V13.834C2.08337 14.3041 2.43724 14.6916 2.89314 14.7445L3.00004 14.7507H13C13.4701 14.7507 13.8576 14.3968 13.9105 13.9409L13.9167 13.834V12.1674C13.9167 11.7531 14.2525 11.4174 14.6667 11.4174Z"
      fill="#0E1114"
    />
    <path
      d="M7.46971 0.803654C7.73598 0.537388 8.15264 0.513182 8.44625 0.731036L8.53037 0.803654L12.697 4.97032C12.9899 5.26321 12.9899 5.73809 12.697 6.03098C12.4308 6.29725 12.0141 6.32145 11.7205 6.1036L11.6364 6.03098L8.00004 2.39465L4.3637 6.03098C4.09744 6.29725 3.68077 6.32145 3.38716 6.1036L3.30304 6.03098C3.03678 5.76471 3.01257 5.34805 3.23043 5.05444L3.30304 4.97032L7.46971 0.803654Z"
      fill="#0E1114"
    />
    <path
      d="M8.00003 0.583984C8.37973 0.583984 8.69352 0.866138 8.74318 1.23221L8.75003 1.33398V11.334C8.75003 11.7482 8.41424 12.084 8.00003 12.084C7.62033 12.084 7.30654 11.8018 7.25688 11.4358L7.25003 11.334V1.33398C7.25003 0.919771 7.58582 0.583984 8.00003 0.583984Z"
      fill="#0E1114"
    />
  </svg>
);

export default Upload;
