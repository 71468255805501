import React from 'react';
import withWrapper from 'hocs/withWrapper';

const TrashIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7442 4.35647C15.6712 3.45711 14.9182 2.75 14 2.75H10L9.85647 2.7558C8.95711 2.82881 8.25 3.58183 8.25 4.5V6.75H5.00877H4.9929H4L3.89823 6.75685C3.53215 6.80651 3.25 7.1203 3.25 7.5C3.25 7.91421 3.58579 8.25 4 8.25H4.3099L5.25259 19.5623L5.25733 19.7231C5.3416 21.1083 6.53747 22.25 8 22.25H16L16.1675 22.245C17.6083 22.1584 18.75 20.9625 18.75 19.5L19.69 8.25H20L20.1018 8.24315C20.4678 8.19349 20.75 7.8797 20.75 7.5C20.75 7.08579 20.4142 6.75 20 6.75H15.75V4.5L15.7442 4.35647ZM14.25 6.75V4.5C14.25 4.38165 14.1678 4.28251 14.0573 4.2566L14 4.25H10C9.88165 4.25 9.78251 4.33223 9.7566 4.44268L9.75 4.5V6.75H14.25ZM9 8.25H5.81492L6.75 19.5C6.75 20.1472 7.24187 20.6795 7.87219 20.7435L8 20.75H16C16.6472 20.75 17.1795 20.2581 17.2457 19.5765L17.2526 19.4377L18.1849 8.25H15H9ZM10 10.75C10.3797 10.75 10.6935 11.0322 10.7432 11.3982L10.75 11.5V17.5C10.75 17.9142 10.4142 18.25 10 18.25C9.6203 18.25 9.30651 17.9678 9.25685 17.6018L9.25 17.5V11.5C9.25 11.0858 9.58579 10.75 10 10.75ZM14.7432 11.3982C14.6935 11.0322 14.3797 10.75 14 10.75C13.5858 10.75 13.25 11.0858 13.25 11.5V17.5L13.2568 17.6018C13.3065 17.9678 13.6203 18.25 14 18.25C14.4142 18.25 14.75 17.9142 14.75 17.5V11.5L14.7432 11.3982Z"
      fill="white"
    />
  </svg>
);

export default withWrapper(TrashIcon);
