import React from 'react';

const CheckboxChecked = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.880371" width="16" height="16" rx="4" fill="#3357FF" />
    <path
      d="M12.6962 4.26035C13.0434 3.91322 13.6062 3.91322 13.9533 4.26035C14.2737 4.58078 14.2984 5.08499 14.0273 5.43369L13.9533 5.51743L7.73109 11.7397C7.41066 12.0601 6.90646 12.0847 6.55775 11.8136L6.47401 11.7397L3.80735 9.07298C3.46021 8.72585 3.46021 8.16304 3.80735 7.81591C4.12778 7.49548 4.63198 7.47083 4.98069 7.74196L5.06443 7.81591L7.10255 9.85333L12.6962 4.26035Z"
      fill="white"
    />
  </svg>
);

export default CheckboxChecked;
