import React from 'react';

const CheckboxNotChecked = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99996 0.166666H9.99996C11.0125 0.166666 11.8333 0.987477 11.8333 2V10C11.8333 11.0125 11.0125 11.8333 9.99996 11.8333H1.99996C0.987437 11.8333 0.166626 11.0125 0.166626 10V2C0.166626 0.987477 0.987437 0.166666 1.99996 0.166666ZM9.99996 1.16667H1.99996C1.53972 1.16667 1.16663 1.53976 1.16663 2V10C1.16663 10.4602 1.53972 10.8333 1.99996 10.8333H9.99996C10.4602 10.8333 10.8333 10.4602 10.8333 10V2C10.8333 1.53976 10.4602 1.16667 9.99996 1.16667Z"
      fill="#0E1114"
    />
  </svg>
);

export default CheckboxNotChecked;
