import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { actions, connect } from 'store';
import MainImage from './components/MainImage';
import SocialSections from './components/SocialSections';
import styles from './index.module.scss';

const Main = ({ isLoadingFromDb }) => {
  useEffect(
    () => {
      if (isLoadingFromDb) {
        actions.openPopup({ type: 'loader', isOpen: true });
      }
    },
    [isLoadingFromDb],
  );

  return (
    <main className={styles.mainContent}>
      <div className={styles.mainImageContainer}>
        <MainImage />
      </div>
      <SocialSections />
    </main>
  );
};

const mapStateToProps = ({ isLoadingFromDb }) => ({
  isLoadingFromDb,
});

Main.propTypes = {
  isLoadingFromDb: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Main);
