import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CloseIcon from 'assets/icons/ModalClose';
import CustomPopup from './components/CustomPopup';
import DefaultPopup from './components/DefaultPopup';
import SignupPopup from './components/SignupPopup';
import LoaderPopup from './components/LoaderPopup';

import styles from './index.module.scss';

class ModalContent extends Component {
  handleClickOutside() {
    const { onClose, type } = this.props;
    if (type !== 'loader') {
      onClose();
    }
  }

  renderTemplate() {
    const {
      title,
      onClose,
      buttons,
      type,
      description,
      shareSection,
    } = this.props;

    switch (type) {
      case 'signup': {
        return (
          <SignupPopup
            onClose={e => {
              e.persist();
              onClose();
            }}
          />
        );
      }

      case 'loader': {
        return <LoaderPopup onClose={onClose} />;
      }

      case 'custom': {
        return (
          <div className={classnames(styles.modalContent, styles[type])}>
            <p className={styles.modalTitle}>{title}</p>
            <button
              type="button"
              className={styles.modalClose}
              onClick={event => {
                event.persist();
                onClose();
              }}
            >
              <CloseIcon fill={type === 'custom' ? '#fff' : '#000'} />
            </button>
            <CustomPopup
              description={description}
              shareSection={shareSection}
            />
          </div>
        );
      }

      default: {
        return (
          <div className={classnames(styles.modalContent, styles[type])}>
            <p className={styles.modalTitle}>{title}</p>
            <button
              type="button"
              className={styles.modalClose}
              onClick={event => {
                event.persist();
                onClose();
              }}
            >
              <CloseIcon fill={type === 'custom' ? '#fff' : '#000'} />
            </button>
            <DefaultPopup description={description} buttons={buttons} />
          </div>
        );
      }
    }
  }

  render() {
    return this.renderTemplate();
  }
}

ModalContent.defaultProps = {
  buttons: {},
  shareSection: '',
  title: '',
};

ModalContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  buttons: PropTypes.shape({
    whiteButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
    blueButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  }),
  shareSection: PropTypes.string,
};

export default enhanceWithClickOutside(ModalContent);
