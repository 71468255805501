import React from 'react';
import propTypes from 'prop-types';

import { TITLES, DESCRIPTIONS } from 'constants/texts/texts';
import Close from 'assets/icons/ModalClose';
import styles from './index.module.scss';

const Header = ({
  socialName,
  selectedSections,
  children,
  selectAllImages,
  className,
}) => (
  <header className={styles[className]}>
    <div className={styles.socialHeadingWrapper}>
      <h2 className={styles.socialHeading}>
        <>
          {children}
          {TITLES[socialName]}
        </>
      </h2>
      <button
        onClick={() => selectAllImages(socialName)}
        className={styles.selectAllButton}
        disabled={!!className}
        type="button"
        data-rh={
          !selectedSections.includes(socialName)
            ? 'Select all images for this platform to download'
            : undefined
        }
      >
        {selectedSections.includes(socialName) ? 'Deselect All' : 'Select All'}
        {selectedSections.includes(socialName) && <Close />}
      </button>
    </div>
    <div className="ir-platform__description">
      <h3
        dangerouslySetInnerHTML={{ __html: DESCRIPTIONS[socialName] }}
        className={styles.socialSubtitle}
      />
    </div>
  </header>
);

Header.propTypes = {
  selectAllImages: propTypes.func.isRequired,
  socialName: propTypes.string.isRequired,
  selectedSections: propTypes.arrayOf(propTypes.string).isRequired,
  children: propTypes.node.isRequired,
  className: propTypes.string.isRequired,
};

export default Header;
