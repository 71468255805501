import React from 'react';
import PropTypes from 'prop-types';
import Close from 'assets/icons/Close';

import styles from './index.module.scss';

const Modal = ({ error, urlInput, onChangeUrl, onSubmitUrl, onClose }) => (
  <div className={styles.modalWrapper}>
    <button type="button" className={styles.modalClose} onClick={onClose}>
      <Close />
    </button>
    <p className={styles.modalLabel}>Import from URL</p>
    <div className={styles.modalContent}>
      <div className={styles.modalInputWrapper}>
        <span
          style={{
            color: error ? '#DC3636' : '',
          }}
          className={styles.modalInputWrapperLabel}
        >
          Image URL
        </span>
        <input
          style={{
            borderColor: error ? '#DC3636' : '',
          }}
          type="text"
          value={urlInput}
          placeholder="Enter link URL"
          onChange={onChangeUrl}
        />
        {error ? <p className={styles.modalError}>{error}</p> : null}
      </div>
    </div>
    <div className={styles.modalButton}>
      <button onClick={onSubmitUrl} type="button" disabled={!urlInput}>
        Done
      </button>
    </div>
  </div>
);

Modal.defaultProps = {
  error: '',
};

Modal.propTypes = {
  error: PropTypes.string,
  urlInput: PropTypes.string.isRequired,
  onChangeUrl: PropTypes.func.isRequired,
  onSubmitUrl: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
