const getImagePosition = (width, height) => {
  if (width > height) {
    return {x: 0.5, y: 0.5};
  }
  if (width < height) {
    return {x: 0.5, y: 0.2};
  }
  return {x: 0.5, y: 0.5};
};

export default getImagePosition;
