import React from 'react';

const Twitter = () => (
  <svg
    width="41px"
    height="40px"
    viewBox="0 0 41 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Image-Uploaded-Full-size"
        transform="translate(-848.000000, -10512.000000)"
      >
        <g id="socials" transform="translate(734.000000, 10512.000000)">
          <g id="twitter" transform="translate(114.000000, 0.000000)">
            <path
              d="M20.0374435,0.975121951 C9.54291787,0.975121951 1.0052882,9.50926829 1.0052882,19.9995122 C1.0052882,30.4892683 9.54291787,39.0234146 20.0374435,39.0234146 C30.5314811,39.0234146 39.0686228,30.4892683 39.0686228,19.9995122 C39.0686228,9.50926829 30.5314811,0.975121951 20.0374435,0.975121951 L20.0374435,0.975121951 Z M20.0374435,39.9990244 C9.00513748,39.9990244 0.0292802389,31.0273171 0.0292802389,19.9995122 C0.0292802389,8.97121951 9.00513748,-0.000487804878 20.0374435,-0.000487804878 C31.0697495,-0.000487804878 40.0446308,8.97121951 40.0446308,19.9995122 C40.0446308,31.0273171 31.0697495,39.9990244 20.0374435,39.9990244 L20.0374435,39.9990244 Z"
              id="Fill-4"
              fill="#FFFFFF"
            />
            <circle
              id="Oval"
              fill="#FFFFFF"
              fillRule="nonzero"
              cx="20"
              cy="20"
              r="20"
            />
            <path
              d="M28.8210272,15.5034146 C28.1744219,15.7897561 27.4795042,15.9834146 26.7509143,16.0702439 C27.4960964,15.6243902 28.067549,14.9190244 28.3354632,14.0780488 C27.6385935,14.4907317 26.8685232,14.7902439 26.0457485,14.9507317 C25.3903592,14.2507317 24.4524155,13.8136585 23.4154071,13.8136585 C21.4258148,13.8136585 19.8119857,15.4268293 19.8119857,17.4160976 C19.8119857,17.6990244 19.8427299,17.9731707 19.9051944,18.2365854 C16.910314,18.0858537 14.2545963,16.6531707 12.4753338,14.4721951 C12.1649633,15.0058537 11.9883058,15.6243902 11.9883058,16.284878 C11.9883058,17.5336585 12.624663,18.6365854 13.5909109,19.2834146 C13.0014021,19.2653659 12.4445895,19.1019512 11.9575616,18.8336585 L11.9575616,18.877561 C11.9575616,20.6243902 13.2005077,22.0804878 14.8499612,22.4102439 C14.5478867,22.4946341 14.2301961,22.5370732 13.9003054,22.5370732 C13.6680155,22.5370732 13.4420697,22.5156098 13.2224679,22.4731707 C13.6811916,23.9043902 15.0110025,24.9463415 16.5892074,24.9741463 C15.3550453,25.9409756 13.8002646,26.5165854 12.1127468,26.5165854 C11.8218965,26.5165854 11.5344621,26.5004878 11.2528838,26.4668293 C12.8481688,27.4882927 14.7426003,28.084878 16.7770889,28.084878 C23.408575,28.084878 27.0324926,22.5960976 27.0324926,17.8346341 C27.0324926,17.6780488 27.0295646,17.5219512 27.0227325,17.3682927 C27.7274103,16.86 28.3398553,16.2253659 28.8210272,15.5034146"
              id="Fill-5"
              fill="#6A93F5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Twitter;
