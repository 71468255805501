import Swipe from 'react-easy-swipe';
import React from 'react';

const Mobile = ({ step, setStep, swipeLeft, swipeRight }) => (
  <header className="ir-header ir-header--mobile">
    <div className="ir-header__titles">
      <h1>Free Image Resizer</h1>
      <h2>
        Resize Images in
        <br /> 3 Easy Steps
      </h2>
      <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight}>
        <div className={`ir-header__steps ir-header__steps--step-${step}`}>
          <div className="ir-header__step" onClick={setStep(1)}>
            <span className="ir-header__step-number">1</span>
            <h3 className="ir-header__step-title">Upload an image</h3>
            <span className="ir-header__step-description">
              Upload an image or paste a link to the
              <br />
              image and we will do it for you
            </span>
          </div>
          <div className="ir-header__step" onClick={setStep(2)}>
            <span className="ir-header__step-number">2</span>
            <h3 className="ir-header__step-title">Select the size</h3>
            <span className="ir-header__step-description">
              Choose as many image sizes as
              <br /> you like from the list below.
            </span>
          </div>
          <div className="ir-header__step" onClick={setStep(3)}>
            <span className="ir-header__step-number">3</span>
            <h3 className="ir-header__step-title">Download</h3>
            <span className="ir-header__step-description">
              Download your resized image or convert
              <br /> to mp4 with the Promo video editor
            </span>
          </div>
        </div>
      </Swipe>
      <div className="ir-header__steps-dots">
        {[1, 2, 3].map(i => (
          <div
            onClick={setStep(i)}
            key={i}
            className={`ir-header__steps-dot ${
              i === step ? 'ir-header__steps-dot--active' : ''
            }`}
          />
        ))}
      </div>
    </div>
  </header>
);

export default Mobile;
