import React from 'react';
import propTypes from 'prop-types';

import InputWithLabel from './components/InputWithLabel';
import Checkbox from './components/Checkbox';

import styles from './index.module.scss';

function InputSection(props) {
  const {
    resizeInputs = {
      width: null,
      height: null,
      scaleX: null,
      scaleY: null,
    },
    originalImage,
    actionAvailable,
    onChangeInputWidth,
    onChangeInputHeight,
    onChangeInputXScale,
    onChangeInputYScale,
    onChangeAspectRatio,
    turnIntoVideo,
    downloadImage,
    ratioValue,
  } = props;
  return (
    <div className={styles.rightWrapper}>
      <p className={styles.topLabel}>Custom Resize</p>
      <div className={styles.mainImageInputContainer}>
        <div className={styles.mainImageInputContainerRow}>
          <InputWithLabel
            disabled={!actionAvailable}
            placeholder="0"
            value={resizeInputs.width}
            title="Width"
            onChange={onChangeInputWidth}
            type="number"
            measure="px"
            round
          />
          <InputWithLabel
            disabled={!actionAvailable}
            placeholder="0"
            value={resizeInputs.height}
            title="Height"
            onChange={onChangeInputHeight}
            type="number"
            measure="px"
            round
          />
        </div>
        <div className={styles.mainImageInputContainerRow}>
          <InputWithLabel
            disabled={!actionAvailable}
            placeholder="100"
            value={(resizeInputs.scaleX * 100).toFixed(0)}
            title="X Scale"
            onChange={onChangeInputXScale}
            type="number"
            measure="%"
          />
          <InputWithLabel
            disabled={!actionAvailable}
            placeholder="100"
            value={(resizeInputs.scaleY * 100).toFixed(0)}
            title="Y Scale"
            onChange={onChangeInputYScale}
            type="number"
            measure="%"
          />
        </div>
        <Checkbox
          checked={ratioValue}
          disabled={!actionAvailable}
          text="Lock aspect ratio"
          id="ratio"
          onChange={onChangeAspectRatio}
        />
        {originalImage.source &&
          actionAvailable && (
            <div className={styles.buttonsWrapper}>
              <button
                type="button"
                onClick={turnIntoVideo}
                disabled={!actionAvailable}
                data-rh="Upload your photo into Promo.com’s video maker and easily convert it to video"
              >
                Convert to video
              </button>
              <button
                type="button"
                onClick={downloadImage}
                disabled={!actionAvailable}
              >
                Download
              </button>
            </div>
          )}
      </div>
    </div>
  );
}

InputSection.propTypes = {
  originalImage: propTypes.shape({
    width: propTypes.string,
    height: propTypes.string,
    source: propTypes.string,
    xScale: propTypes.string,
    yScale: propTypes.string,
  }).isRequired,
  resizeInputs: propTypes.shape({
    width: propTypes.number,
    height: propTypes.number,
    scaleX: propTypes.number,
    scaleY: propTypes.number,
  }).isRequired,
  actionAvailable: propTypes.bool.isRequired,
  onChangeInputWidth: propTypes.func.isRequired,
  onChangeInputHeight: propTypes.func.isRequired,
  onChangeInputXScale: propTypes.func.isRequired,
  onChangeInputYScale: propTypes.func.isRequired,
  onChangeAspectRatio: propTypes.func.isRequired,
  turnIntoVideo: propTypes.func.isRequired,
  downloadImage: propTypes.func.isRequired,
  ratioValue: propTypes.bool.isRequired,
};

export default InputSection;
