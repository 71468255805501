import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'store';
import CookiesNotice from '@easyhi/promo-design-system/lib/cookie-notice';
import PromoFooter from '@easyhi/promo-composite-components/lib/components/promo-footer';
import PromoFooterConfig from '@easyhi/promo-composite-components/lib/config/footer-config';
import { userService } from 'services/user-session-service';
import { trackInit, changeGlobalParams } from 'utils/promoReportingWrapper';
import { Tooltip, TooltipClick } from 'components/Tooltip';
import Aside from '../components/Aside';
import Header from '../components/Header';
import StickyBar from '../components/StickyBar';
import Main from '../components/Main';
import Modal from '../components/Modal';
import useAccountInfo from '../hooks/use-account-info';
import EmbedPricingProvider from '../providers/EmbedPricingProvider';
import UserLimitsProvider from '../providers/UserLimitsProvider';
import EmbedPricing from '../components/EmbedPricing';

import '../styles/promo-design-system.scss';
import '../styles/composite-components.scss';
import './styles/main.scss';

const COOKIES_NOTICE_KEY = 'cookie_policy';

const App = ({ popupParams, pagePlatform }) => {
  const [showCookiesNotice, setShowCookiesNotice] = useState(false);
  const accountInfo = useAccountInfo();

  useEffect(() => {
    const handleReporting = async () => {
      await changeGlobalParams({
        asset: 'image-resizer',
        pageType: 'image-resizer',
      });
      trackInit('IMR homepage', {
        product: 'image resizer',
        pagePlatform,
      });
    };

    handleReporting();
  }, []);

  const isCookiesAccepted = () =>
    localStorage.getItem(COOKIES_NOTICE_KEY) === 'true';

  useEffect(() => {
    (async () => {
      const user = await userService.getUser();
      setShowCookiesNotice(!user && !isCookiesAccepted());
    })();
  }, []);

  const onAccept = useCallback(async () => {
    setShowCookiesNotice(false);
    localStorage.setItem(COOKIES_NOTICE_KEY, 'true');
    (await window.promoReporting).enableReporting();
  }, []);

  const onDecline = useCallback(() => {
    setShowCookiesNotice(false);
  }, []);

  const {
    isOpen,
    onClose,
    type,
    title,
    description,
    buttons,
    payload,
  } = popupParams;

  return (
    <UserLimitsProvider accountInfo={accountInfo}>
      <EmbedPricingProvider>
        <Tooltip />
        <TooltipClick />
        <Modal
          type={type}
          isOpen={isOpen}
          onClose={onClose}
          title={title}
          description={description}
          buttons={buttons}
          payload={payload}
        />
        <Header accountInfo={accountInfo} />
        <Aside />
        <Main />
        <StickyBar />
        <PromoFooter items={PromoFooterConfig} />
        {showCookiesNotice && (
          <CookiesNotice onAccept={onAccept} onDecline={onDecline} />
        )}
        <EmbedPricing accountInfo={accountInfo} />
      </EmbedPricingProvider>
    </UserLimitsProvider>
  );
};

const mapStateToProps = ({ pagePlatform, popupParams }) => ({
  pagePlatform,
  popupParams,
});

export default connect(mapStateToProps)(App);
