import React, { useContext, useMemo, useState } from 'react';

const Context = React.createContext(null);

const EmbedPricingProvider = ({ children }) => {
  const embedPricingState = useState(false);
  const embedPricingParamsState = useState();

  const value = useMemo(
    () => ({
      embedPricingState,
      embedPricingParamsState,
    }),
    [embedPricingState, embedPricingParamsState],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useEmbedPricing = () => useContext(Context);

export default EmbedPricingProvider;
