import React from 'react';

const ArrowUp = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.64645 0.646447C4.82001 0.47288 5.08944 0.453595 5.28431 0.588591L5.35355 0.646447L9.35355 4.64645C9.54882 4.84171 9.54882 5.15829 9.35355 5.35355C9.17999 5.52712 8.91056 5.5464 8.71569 5.41141L8.64645 5.35355L5 1.707L1.35355 5.35355C1.17999 5.52712 0.910563 5.5464 0.715695 5.41141L0.646447 5.35355C0.47288 5.17999 0.453595 4.91056 0.588591 4.71569L0.646447 4.64645L4.64645 0.646447Z"
      fill="#0080FF"
    />
  </svg>
);

const ArrowDown = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646447 0.646447C0.820013 0.47288 1.08944 0.453595 1.28431 0.588591L1.35355 0.646447L5 4.293L8.64645 0.646447C8.82001 0.47288 9.08944 0.453595 9.28431 0.588591L9.35355 0.646447C9.52712 0.820013 9.5464 1.08944 9.41141 1.28431L9.35355 1.35355L5.35355 5.35355C5.17999 5.52712 4.91056 5.5464 4.71569 5.41141L4.64645 5.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
      fill="#0080FF"
    />
  </svg>
);

export { ArrowUp, ArrowDown };
