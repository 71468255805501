import React from 'react';

const VideoIcon = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83333 0.25H3.16667C1.83198 0.25 0.75 1.33198 0.75 2.66667V9.33333C0.75 10.668 1.83198 11.75 3.16667 11.75H9.83333C11.168 11.75 12.25 10.668 12.25 9.33333V8.88007L14.9588 10.2342C15.4496 10.4794 16.0322 10.4532 16.4989 10.1649C16.9655 9.87656 17.2497 9.36726 17.25 8.81873V3.18167C17.2497 2.63274 16.9655 2.12344 16.4989 1.83512C16.0322 1.54681 15.4496 1.52057 14.9589 1.76577L12.25 3.11993V2.66667C12.25 1.33198 11.168 0.25 9.83333 0.25ZM12.25 4.797V7.203L15.6294 8.89243C15.6466 8.90104 15.666 8.90329 15.6843 8.89934L15.7105 8.88878C15.735 8.87361 15.75 8.8468 15.75 8.81833V3.18207C15.75 3.1532 15.735 3.12639 15.7105 3.11122C15.6859 3.09604 15.6552 3.09466 15.6295 3.10752L12.25 4.797ZM10.75 4.33333V2.66667C10.75 2.16041 10.3396 1.75 9.83333 1.75H3.16667C2.66041 1.75 2.25 2.16041 2.25 2.66667V9.33333C2.25 9.83959 2.66041 10.25 3.16667 10.25H9.83333C10.3396 10.25 10.75 9.83959 10.75 9.33333V7.66667V4.33333Z"
      fill="#3357FF"
    />
  </svg>
);

export default VideoIcon;
