import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-scroll';

import trackEvent from 'utils/promoReportingWrapper';
import { connect } from 'store';
import { PLATFORM_ICONS } from 'constants/texts/texts';
import SocialNav from 'assets/icons/Socials/social-nav';

import styles from './index.scss';

class Aside extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  setNextPage = () => {
    const { page } = this.state;
    if (page === 3) return;
    this.setState(prevState => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  setPrevPage = () => {
    const { page } = this.state;
    if (page === 1) return;
    this.setState(prevState => ({
      ...prevState,
      page: prevState.page - 1,
    }));
  };

  setPage = page => () => {
    this.setState({ page });
  };

  render() {
    const { thumbnails } = this.props;
    const { page } = this.state;

    const socials = Object.keys(thumbnails).map(section =>
      section.split(' ').join('-'),
    );

    return (
      <aside className={`ir-aside ir-aside--${page}`}>
        <div
          onClick={this.setPrevPage}
          key="aside_top"
          className="ir-aside__icon ir-aside__icon--top"
        >
          <SocialNav />
        </div>
        <div className="ir-aside__icons">
          <div className="ir-aside__icons-inner">
            {socials.map((section, i) => {
              const Icon = PLATFORM_ICONS[section];
              return (
                <div key={`aside_${section}`} className="ir-aside__icon">
                  <Link
                    onClick={() =>
                      trackEvent('IMR category bar clicked', {
                        categoryType: section,
                      })
                    }
                    offset={-96}
                    key={String(i)}
                    activeClass={styles.isActive}
                    href={`#${section.split(' ').join('-')}`}
                    to={`${section}`}
                    duration={500}
                    smooth
                    spy
                  >
                    <span className={styles.icon}>
                      <Icon />
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div
          onClick={this.setNextPage}
          key="aside_bottom"
          className="ir-aside__icon ir-aside__icon--bottom"
        >
          <SocialNav />
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ pagePlatform, thumbnails }) => ({
  pagePlatform,
  thumbnails,
});

Aside.propTypes = {
  thumbnails: propTypes.object.isRequired,
};

export default connect(mapStateToProps)(Aside);
